import '../../../src/styles/healthInfo.css';

const OfflineServersPastWeek = ( {servers} ) => {    
  return (   
      <div className='table-container-device' style={{ fontSize: '.85em' }}>        
    <table striped bordered hover size="sm" variant="dark" responsive>
        <thead>
            <tr>
              <th>Business</th>      
              <th>Site name</th>
              <th>Server name</th>             
            </tr>
        </thead>
        <tbody>
            {servers.map((server, index) => (
                    <tr key={index}>
                      <td>{server.business}</td>
                      <td>{server.site}</td>
                      <td>{server.serverName}</td>
                    </tr>
            ))}
        </tbody>
    </table>
</div>

    
  )
}

export default OfflineServersPastWeek;