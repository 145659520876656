import '../../../src/styles/healthInfo.css';

const OfflineDevicesPastWeek = ( {devices} ) => {   
  return (
    <div className='table-container-device' style={{ fontSize: '.85em' }}>        
    <table striped bordered hover size="sm" variant="dark" responsive>
        <thead>
            <tr>
                <th>Site name</th>
                <th >Server name</th>
                <th >Camera Name</th>              
            </tr>
        </thead>
        <tbody>
            {devices.map((device, index) => (
                <tr key={index}>
                    <td>{device.site}</td>
                    <td>{device.serverName}</td>
                    <td>{device.cameraName}</td>
                </tr>
            ))}
        </tbody>
    </table>
</div>
    
  )
}

export default OfflineDevicesPastWeek;