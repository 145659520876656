import React, {useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { faServer, faVideoCamera } from '@fortawesome/free-solid-svg-icons';
import { RingLoader } from 'react-spinners';
// leaflet
import { MapContainer, TileLayer, Marker, LayersControl, LayerGroup, Tooltip } from 'react-leaflet';
import { Icon, LatLng, LatLngBounds } from 'leaflet';
// import { ToastContainer, toast, collapseToast  } from 'react-toastify';

import "leaflet/dist/leaflet.css";

// Hive images

import Home from '../home/Home';
// import { webURL, card1Text, card2Text, card3Text } from '../../config';

import { listServers } from '../../redux/actions/serverActions';
import { fetchSelectedBusiness } from '../../redux/actions/fetchSelectedBusinessActions';
import Server from './Server';
import OfflineServersPastWeek from './OfflineServersPastWeek';
import OfflineDevicesPastWeek from './OfflineDevicesPastWeek';
import UnAckAlerts from './UnAckAlerts';
import Storage from './Storage';
import HealthCharts from './HealthCharts';

import '../../../src/styles/healthInfo.css';

import AdditionalInfo from './AdditionalInfo';
import Footer from '../Footer';
import SideBar from '../SideBar';
import SideBarSmallScreen from '../SideBarSmallScreen';
import AnalyticInfo from './AnalyticInfo';
import Switch from './Switch';
import CustomTooltipPortLayout from '../tooltips/CustomTooltipPortLayout'


import PORT_UP_WHITE_ICON from '../../images/port-up-white.png';

import PORT_UP_ICON from '../../images/port-up.png';
import PORT_DOWN_ICON from '../../images/port-down.png';

import Port from './Port';


const Servers = () => {  
  const mapRef = useRef(null);
  const [selectedBusiness, setSelectedBusiness] = useState(''); 
  const [selectedSite, setSelectedSite] = useState('All'); 
  const [selectedSI, setSelectedSI] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingSelectedBusiness, setLoadingSelectedBusiness] = useState(false);
  const [noServersData, setNoServersData] = useState(false);
  const [isHealthTableVisible, setIsHealthTableVisible] = useState(false);
  const [isStorageTableVisible, setIsStorageTableVisible] = useState(false);
  const [isHealthyAnalyticsTableVisible, setIsHealthyAnalyticsTableVisible] = useState(false);
  const [isHealthyUPSTableVisible, setIsHealthyUPSTableVisible] = useState(false);
  const [isHealthySwitchesTableVisible, setIsHealthySwitchesTableVisible] = useState(false);
  const [isPortLayoutVisible, setIsPortLayoutVisible] = useState(false);


  const [showPastWkOfflineServer, setShowPastWkOfflineServer] = useState(false);
  const [showPastWkOfflineDevices, setShowPastWkOfflineDevices] = useState(false);
  const [showUnAckAlerts, setShowUnAckAlerts] = useState(false);
  const [date,setDate] = useState(new Date());
  const [mapRefLoaded, setMapRefLoaded] = useState(false);
  const [center, setCenter] = useState([0, 0]);
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const [sortConfigHealthy, setsortConfigHealthyHealthy] = useState({ key: 'serverOnlineDays', direction: 'descending' });
  const [sortConfigAlerts, setsortConfigAlerts] = useState({ key: 'serverStatus', direction: 'ascending' });
  const { BaseLayer, Overlay } = LayersControl;
 
  const isSmallScreen = window.innerWidth < 600;

  const user = localStorage.getItem('email');

  const dispatch = useDispatch();

  // TEstingnnnnnn
  // const portCycleOutcomes = useSelector(state => state.portCycleOutcomes);
  // useEffect(() => {
  //   console.log("Line 87 the port-cycle outcomes from servers are ", JSON.stringify(portCycleOutcomes, null, 2));
  // }, [])
  //

  const handleSortHealthy = (columnKey) => {
    let direction = 'ascending';
    if (sortConfigHealthy.key === columnKey && sortConfigHealthy.direction === 'ascending') {
      direction = 'descending';
    }
    setsortConfigHealthyHealthy({ key: columnKey, direction });
  };

  const handleSortAlert = (columnKey) => {
    let direction = 'ascending';
    if (sortConfigAlerts.key === columnKey && sortConfigAlerts.direction === 'ascending') {
      direction = 'descending';
    }
    setsortConfigAlerts({ key: columnKey, direction });
  };

  // Total data
  const serversResponseData = useSelector(state => state.serversData);
  const servers = serversResponseData.servers;
  const serversResponsecode = serversResponseData.code;

  const orangeIcon = new Icon({
    iconUrl: '/markerIcons/marker-icon-orange.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const greenIcon = new Icon({
    iconUrl: '/markerIcons/marker-icon-green.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

 
  const redIcon = new Icon({
    iconUrl: '/markerIcons/marker-icon-red.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });
  
    /** calculates the average latitude & longitude of all sites */
    function calculateMapCenter() {
      let sumLatitude = 0, sumLongitude = 0;
      let count = 0;
    
      servers.forEach(site => {
        if (site.latitude && site.longitude && !isNaN(site.latitude) && !isNaN(site.longitude)) {
          sumLatitude += site.latitude;
          sumLongitude += site.longitude;
          count++;
        }
      });
    
      if (count === 0) return [0, 0]; // Default to [0, 0] if no valid coordinates
    
      return [sumLatitude / count, sumLongitude / count];
    }

  /** re-centers and re-zooms the map to show all pins */
  function recenterMap() {
    setCenter(calculateMapCenter());

    mapRef.current.panTo(new LatLng(center[0], center[1]));

    var bounds = new LatLngBounds();
    servers.forEach(site => {
      if (site.latitude !== null && site.longitude !== null && site.latitude && site.longitude && !isNaN(site.latitude) && !isNaN(site.longitude)) {
        bounds.extend([site.latitude, site.longitude]);       
      }  
    });

    if (bounds.isValid()) { // Check if bounds are valid
      mapRef.current.fitBounds(bounds); // Fit the map viewport to the updated bounds
    }
  }

  // past week offline servers modal
  const handleClosePastWkOfflineServer = () => {
    setShowPastWkOfflineServer(false);
  }
  
  const handleShowPastWkOfflineServer = () => {
    setShowPastWkOfflineServer(true);
  }
  
  // past week offline cameras modal
  const handleClosePastWkOfflineDevices = () => {
    setShowPastWkOfflineDevices(false);
  }
  
  const handleShowPastWkOfflineDevices = () => {
    setShowPastWkOfflineDevices(true);
  }

  // unacknowledged alerts modal
  const handleCloseUnAckAlerts = () => {
    setShowUnAckAlerts(false);
  }

  const handleShowUnAckAlerts = () => {
    setShowUnAckAlerts(true);
  }

  const handleOpenPortLayout = () => {
    setIsPortLayoutVisible(true);
  }

  const handleClosePortLayout = () => {
    setIsPortLayoutVisible(false);
    // toast.dismiss();
  }



  // Alert data
  const serversAlert = servers?.filter((row) => row.devicesOffline !== 0 || row.serverStatus === 'Offline');

  // Healthy data
  const serversHealthy = servers?.filter((row) => row.devicesOffline === 0 && row.serverStatus === 'Online');

  // Healthy analytics toggle
  const toggleHealthyAnalyticsTableVisibility = () => {
    setIsHealthyAnalyticsTableVisible(prevState => !prevState);
  };

  // Healthy UPS toggle
  const toggleHealthyUPSTableVisibility = () => {
    setIsHealthyUPSTableVisible(prevState => !prevState);
  };

  // Healthy Switches toggle
  const toggleHealthySwitchesTableVisibility = () => {
    setIsHealthySwitchesTableVisible(prevState => !prevState);
  };

  // Healthy data toggle
  const toggleHealthTableVisibility = () => {
    setIsHealthTableVisible(prevState => !prevState);
  };

  // Storage data toggle
  const toggleStorageTableVisibility = () => {
    setIsStorageTableVisible(prevState => !prevState);
  };

  // Additional Info data toggle
  // const toggleInfoTableVisibility = () => {
  //   setIsInfoTableVisible(prevState => !prevState);
  // };

  // filter data based on business - total
  const handleBusinessChange = (event) => {
    setSelectedBusiness(event.target.value);
    setSelectedSite('All');
    dispatch(fetchSelectedBusiness(event.target.value));
  };

  //const uniqueBusinesses = ['All', ...new Set(servers.map((row) => row.business))].sort();

  // optimized code
  const fetchUserResponse = useSelector(state => state.fetchUserResponse);
  const businesses = fetchUserResponse?.businesses;
  const filteredSites = businesses?.filter(obj => obj[selectedBusiness] !== undefined);
  // Extracting values from filtered sites
  let selectedSites = [];  
  if (selectedBusiness) {
     selectedSites = filteredSites?.flatMap(obj => Object.values(obj))[0];    
  }
  // sorted sites
  //const sortedSites = ['All', ...new Set(selectedSites.map((site) => site))].sort();
  const sortedSites = [...new Set(selectedSites.map((site) => site))].sort();
if (!sortedSites.includes('All')) {
    sortedSites.unshift('All');
}

  let businessNames = businesses?.map(business => Object.keys(business)[0]);
  // unique business names
  businessNames = businessNames?.sort();
  
  // total data
  const filteredDataBusiness =
    selectedBusiness === 'All'
      ? servers
      : servers.filter((row) => row.business === selectedBusiness);

  // Alert data - all types
  const filteredDataBusinessAlert =
    selectedBusiness === 'All'
      ? serversAlert
      : serversAlert.filter((row) => row.business === selectedBusiness);
  
  // Healthy data
  const filteredDataBusinessHealthy =
    selectedBusiness === 'All'
      ? serversHealthy
      : serversHealthy.filter((row) => row.business === selectedBusiness);

  // returns SI name given site name
  const getSIfromSite = (site) => {
    for (const server of servers) {
      if (server.site === site) {
        return server.si;
      }
    }
    return '';
  }
  
  // filter data based on site
  const handleSiteChange = (event) => {
    setSelectedSite(event.target.value);
    const si = getSIfromSite(event.target.value);
    setSelectedSI(si);
  };

  // Total data
  const filteredDataSite =
  selectedSite === 'All'
    ? filteredDataBusiness
    : filteredDataBusiness.filter((row) =>
        row.site === selectedSite
      );
  
  // past week offline devices
  const pastWeekOfflineDevices = () => {
    let totalPastWkOfflineDevices = [];
    for (let i = 0; i < filteredDataSite.length; i++) {
      let eachServerData = [];

      eachServerData = filteredDataSite[i].devicesOfflinePastWeek;
      
      if (eachServerData) {
        for (let j = 0; j < eachServerData.length; j++) {
          totalPastWkOfflineDevices.push({
            serverName: eachServerData[j].serverName,
            cameraName: eachServerData[j].cameraName,
            site: eachServerData[j].site
          })
      }
    }  
  }
  return totalPastWkOfflineDevices;
}

  // Alert data - all types
  const filteredDataSiteAlert =
  selectedSite === 'All'
    ? filteredDataBusinessAlert
    : filteredDataBusinessAlert.filter((row) => 
        row.site === selectedSite
      );

  // Healthy data
  const filteredDataSiteHealthy =
  selectedSite === 'All'
    ? filteredDataBusinessHealthy
    : filteredDataBusinessHealthy.filter((row) => 
        row.site === selectedSite
      );

  // sort by devices - total data
  const sortedData = [...filteredDataSite].sort((a, b) => b.devicesOffline - a.devicesOffline);
  
  // check if at least one storage status is offline
  const alertStorage = () => {
    if (sortedData.length) {
      for (const eachServer of sortedData) {    
        if (eachServer?.storage?.length) {
          for (const eachStorage of eachServer?.storage) { 
            if (eachStorage.storageStatus !== 'Online') {
              return true;
            }
          }          
        }    
      }
    }
    return false;   
  }

  // check if at least one additional device status is offline
  const alertAdditionalDevices = () => {   
    if (sortedData.length) {
      for (const eachServer of sortedData) {
        if (eachServer?.additionalInfo?.length) {
          for (const eachAdditionalInfo of eachServer?.additionalInfo) {       
            if (eachAdditionalInfo.info_status !== 'Online') {
              return true;
            }
          }          
        }
      }
    }
    return false;   
  }
  // group servers data by site
  function groupBySite(data) {
    return Object.entries(data.reduce((acc, current) => {
      const { business, site, serverName, serverStatus, serverOfflineSince, totalDevices, devicesOffline, latitude, longitude } = current;
  
      if (!acc[site]) {
        acc[site] = [];
      }
  
      acc[site].push({
        business,
        serverName,
        serverStatus,
        serverOfflineSince,
        totalDevices,
        devicesOffline,
        latitude,
        longitude
      });
  
      return acc;
    }, {})).map(([site, servers]) => ({ site, servers }));
  }

  // sort by devices - alert data (offline)
  const sortedDataAlerts = [...filteredDataSiteAlert].sort((a, b) => b.devicesOffline - a.devicesOffline);

  // filter unacknowledged alerts
  const unAckAlerts = sortedDataAlerts.filter((server) => !server.serverAckStatus);  

  // sort by devices - healthy  data
  const sortedDataHealthy = [...filteredDataSiteHealthy].sort((a, b) => b.devicesOnline - a.devicesOnline);

  // sort by all columns - healthy data
  const sortedAllColumnsHealthy = [...sortedDataHealthy].sort((a, b) => {
    if (a[sortConfigHealthy.key] < b[sortConfigHealthy.key]) {
      return sortConfigHealthy.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfigHealthy.key] > b[sortConfigHealthy.key]) {
      return sortConfigHealthy.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  // sort by all columns - alert data
  const sortedAllColumnsAlerts = [...sortedDataAlerts].sort((a, b) => {
    if (a[sortConfigAlerts.key] < b[sortConfigAlerts.key]) {
      return sortConfigAlerts.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfigAlerts.key] > b[sortConfigAlerts.key]) {
      return sortConfigAlerts.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const renderSortIconHealthy = (columnKey) => {
    if (sortConfigHealthy.key === columnKey) {
      return sortConfigHealthy.direction === 'ascending' ? '▲' : '▼';
    }
    // Default to ascending arrow if not sorted yet
    return '▲';
  };

  const renderSortIconAlert = (columnKey) => {
    if (sortConfigAlerts.key === columnKey) {
      return sortConfigAlerts.direction === 'ascending' ? '▲' : '▼';
    }
    // Default to ascending arrow if not sorted yet
    return '▲';
  };

  // handle leaflet Marker color
  function addColorField(siteData) {
    return siteData.map(site => {
      const offlineServers = site.servers.some(server => server.serverStatus === 'Offline');
      const hasOfflineDevices = site.servers.some(server => server.devicesOffline > 0);
  
      let color;
      if (offlineServers) {
        color = 'red';
      } else if (hasOfflineDevices) {
        color = 'orange';
      } else {
        color = 'green';
      }
  
      return {
        ...site,
        color
      };
    });
  }

  const filteredDataBySite = groupBySite(filteredDataSite);
  const filteredDataBySiteWithColor = addColorField(filteredDataBySite);

  // Sort the filteredDataBySiteWithColor array based on the color
  const sortedDataByColor = [...filteredDataBySiteWithColor].sort((a, b) => {
    // Define the order of colors: red > orange > green
    const colorOrder = { "red": 3, "orange": 2, "green": 1 };

    // Get the order of colors for each site
    const colorOrderA = colorOrder[a.color];
    const colorOrderB = colorOrder[b.color];

    // Sort based on the color order
    return colorOrderA - colorOrderB;
  });

  // past week offline servers
  const pastWeekOfflineServers = sortedData.filter((server) => server.serverOfflinePastWeekStatus === true);  
 
  // Chart count data - > Servers
  const totalServers = sortedData.length;
  const offlineServers = sortedData.filter(server => server.serverStatus === 'Offline');
  const totalOfflineServers = offlineServers.length;
  const onlineServers = sortedData.filter(server => server.serverStatus === 'Online');
  const totalOnlineServers = onlineServers.length;

  // Chart count data - > Devices
  const totalOfflineDevices = filteredDataSite.reduce((sum, server) => sum + server.devicesOffline, 0);
  const totalDevices = filteredDataSite.reduce((sum, server) => sum + server.totalDevices, 0);
  const totalOnlineDevices = totalDevices - totalOfflineDevices;

  // Chart count data - > UPS
  // for each server, accumulate total of additional devices
  const totalUPS = filteredDataSite.reduce((sum, server) => sum + server.additionalInfo.length, 0);
  // count number of offline UPS
  let totalOfflineUPS = 0;
  if (filteredDataSite) {
    for (const server of filteredDataSite) {
      for (const additionalDevice of server?.additionalInfo){
        if (additionalDevice?.info_status === 'Offline') {
          totalOfflineUPS ++;
        }
      }
    } 
  }

  const totalOnlineUPS = totalUPS - totalOfflineUPS;

  // Chart count data - > Analytics  
  let totalAnalytics = 0;
  let totalOfflineAnalytics = 0;

  for (const server of filteredDataSite){
    const analyticAlertLength = server.analyticHealthData.analyticAlertData.length;
    const totalApplicationsServer = server.analyticHealthData.totalApplications ? server.analyticHealthData.totalApplications : 0;
    totalAnalytics += totalApplicationsServer;
    totalOfflineAnalytics += analyticAlertLength;
  }
  const totalOnlineAnalytics = totalAnalytics - totalOfflineAnalytics;

  // Chart count data - > Switches
  let totalSwitches = 0;
  let totalOfflineSwitches = 0;
  // console.log(`Line 535 start the loop`);
  for (const server of filteredDataSite){
    // If connected_ports is not empty, there is a switch connected to the server
    // if (server.switchInfo.connected_ports.length) {
    if (server.switchInfo !== null){
      totalSwitches++;
      if (server.switchInfo.switch_status === 0) {
        totalOfflineSwitches++;
      }
    }
  }

  let totalOnlineSwitches = totalSwitches - totalOfflineSwitches;

  // Display donuts
  const chartServersData = totalServers ? 
  {
    labels: [`Online servers`, 'Offline servers'],
    datasets: [
      {
        data: [totalOnlineServers, totalOfflineServers],
        backgroundColor: ['#D0BCFE', '#e9426C'],
      },
    ],
  }
  : 
  {
    labels: ['No Data Available'],
    datasets: [
      {
        data: [1],
        backgroundColor: ['#909090'],
      },
    ],
  }
  ;

  const chartDevicesData =  totalDevices ? 
  { 
    labels: ['Online cameras', 'Offline cameras'],
    datasets: [
      {
        data: [totalOnlineDevices, totalOfflineDevices],
        backgroundColor: ['#4D519E', '#e9426C'],
      },
    ],
  }
  :
  {
    labels: ['No Data Available'],
    datasets: [
      {
        data: [1],
        backgroundColor: ['#909090'],
      },
    ],
  }
  ;

  const chartAnalyticsData = totalAnalytics ?
  {
    labels: ['Online analytics', 'Offline analytics'],
    datasets: [
      {
        data: [totalOnlineAnalytics, totalOfflineAnalytics],
        backgroundColor: ['#31BDFF', '#e9426C'],
      },
    ],
  }
  :
  {
    labels: ['No Data Available'],
    datasets: [
      {
        data: [1],
        backgroundColor: ['#909090'],
        opacity: 0.8
      },
    ],
  }
  ;

  const chartSwitchesData = totalSwitches ?
  {
    labels: ['Online switches', 'Offline switches'],
    datasets: [
      {
        data: [totalOnlineSwitches, totalOfflineSwitches],
        backgroundColor: ['#72DCFF', '#e9426C'],
      },
    ],
  }
  :
  {
    labels: ['No Data Available'],
    datasets: [
      {
        data: [1],
        backgroundColor: ['#909090'],
      },
    ],
  }
  ;

  const chartUPSData = totalUPS ? {
    labels: ['Online UPS', 'Offline UPS'],
    datasets: [
      {
        data: [totalOnlineUPS, totalOfflineUPS],
        backgroundColor: ['#6161D8', '#e9426C'],
      },
    ],
  }
  :
  {
    labels: ['No Data Available'],
    datasets: [
      {
        data: [1],
        backgroundColor: ['#909090'],
      },
    ],
  }
  ;

  const chartData = {
    servers: chartServersData,
    totalOfflineServers: totalOfflineServers,
    totalOnlineServers: totalOnlineServers,
    devices: chartDevicesData,
    totalOnlineDevices: totalOnlineDevices,
    totalOfflineDevices: totalOfflineDevices,
    analytics: chartAnalyticsData,
    totalOfflineAnalytics: totalOfflineAnalytics,
    totalOnlineAnalytics: totalOnlineAnalytics,
    switches: chartSwitchesData,
    totalOfflineSwitches: totalOfflineSwitches,
    totalOnlineSwitches: totalOnlineSwitches,
    ups: chartUPSData,
    totalOfflineUPS: totalOfflineUPS,
    totalOnlineUPS: totalOnlineUPS
  }

  useEffect(() => {
    var timer = setInterval(()=>setDate(new Date()), 1000 )
    return function cleanup() {
        clearInterval(timer)
    }
  });

  useEffect(() => {
    if (user && selectedBusiness) {
      dispatch(listServers({user, selectedBusiness}));
    }
   
  }, [dispatch, user, selectedBusiness]);

  useEffect(() => {
    if (serversResponsecode === 200) {
      setLoading(false);
    }
  }, [servers, loading]);
  
  // set no servers data state
  useEffect(() => {
    if (serversResponsecode === 200 && servers.length === 0) {
      setNoServersData(true);
    }
  }, [servers, serversResponsecode]);

  useEffect(() => {
    if (mapRef.current && servers?.length > 0) {
      recenterMap();
    }
  }, [servers, mapRefLoaded, selectedBusiness]);

  const isBusinessFound = servers.some(server => server.business === selectedBusiness);

  useEffect(() => {    
   if (serversResponsecode === 200 && isBusinessFound) {
    setLoadingSelectedBusiness(false);
   }
   else {
    setLoadingSelectedBusiness(true);
   }
  }, [selectedBusiness, isBusinessFound, serversResponsecode])

  const utcStrToLocal = (utcTimestamp) => {
    // Create a Date object using the UTC timestamp
    const utcDate = new Date(utcTimestamp);

    // Get the local time components
    const localYear = utcDate.getFullYear();
    const localMonth = utcDate.getMonth() + 1; // Months are zero-based
    const localDay = utcDate.getDate();
    const localHours = utcDate.getHours();
    const localMinutes = utcDate.getMinutes();
    const localSeconds = utcDate.getSeconds();
    // Create a new Date object with the local time components
    const localDate = new Date(localYear, localMonth - 1, localDay, localHours, localMinutes, localSeconds);
    // Format the local time as needed
    const formattedLocalTime = localDate.toLocaleString();
    return formattedLocalTime
  }

  // set first business as default selected
  useEffect(() => {
    if (businessNames && !selectedBusiness) {
      setSelectedBusiness(businessNames[0]);
      dispatch(fetchSelectedBusiness(businessNames[0]));
    }      
  }, [selectedBusiness, businessNames, dispatch]);

  // get total number of storages and offline storages
  let totalStorages = 0;
  let totalOfflineStorages = 0;
  sortedData.forEach((server) => {
    totalStorages += server?.storage?.length;
    let server_storages = server?.storage;
    server_storages.forEach((storage) => {
      if (storage.storageStatus === 'Offline') {
        totalOfflineStorages ++;
      }
    })
  })

  // console.log(`Line 767 total online switches is ${totalOnlineSwitches}`);



  return (
     <>
    {!isSmallScreen &&  <div className='health-info-sidebbar' >
     <SideBar />
    </div>}
      {user ?
        loading ?      
        <div className='health-info-loading-img'>
          <RingLoader size={ isSmallScreen ? 200 : 400} color="#F49523"/>          
          <div className='health-info-loading-img-footer'>
            <Footer />  
          </div>       
        </div>
        :
        <div className='health-info-container'>
          <Container >
            {/* select business dropdown menu */}
              {/* top banner -> welcome + current time */}
              <div className='welcome-container'>
                <span className='health-info-welcome-text'>Welcome to your Monitoring Analytics </span>
                <br></br>
                <div className='health-info-welcome-time'>
                 {date.toLocaleTimeString()}&nbsp;
                  {date.toLocaleDateString()}
                </div>
              </div>

               {/* select dropdown menu */}
              <div className='selectors-container'>
                 {/* select business dropdown menu */}
                <label className='health-info-select-label'>Select a business:</label>            
                <select
                  className="health-info-dropdown"
                  value={selectedBusiness}
                  onChange={handleBusinessChange}
                >
                  {businessNames?.map((business, index) => (
                    <option key={index} value={business}>
                      {business}
                    </option>
                  ))}
                </select>                
                
                {/* select site dropdown menu */}
                <label className='health-info-select-label' >Select a site:</label>           
                <select
                  className="health-info-dropdown"
                  value={selectedSite}
                  onChange={handleSiteChange}
                >
                  {sortedSites && sortedSites.map((site, index) => (
                    <option key={index} value={site}>
                      {site}
                    </option>
                  ))}
                </select>
                <span className='total-site-count'>Total sites: {sortedSites.length-1} </span>

              </div>
              
              {
                !loadingSelectedBusiness  ?
              <>

              {/* mapped SI name on selected site */}
              { (selectedSite !== 'ALL' && selectedSI)  &&  <label className='health-info-select-label' >SI Name: <span style={{ opacity: '0.7' }}>{selectedSI}</span></label> }
                    
                {/* Health Charts */}
                <div>
                  <Row className="justify-content-md-center">                
                      <HealthCharts
                        data={chartData}
                        totalOnlineServers={totalOnlineServers}
                        totalOfflineServers={totalOfflineServers}
                        totalOnlineDevices={totalOnlineDevices}
                        totalOfflineDevices={totalOfflineDevices}
                        totalOnlineAnalytics={totalOnlineAnalytics}
                        totalOfflineAnalytics={totalOfflineAnalytics}
                        totalOnlineSwitches={totalOnlineSwitches}
                        totalOfflineSwitches={totalOfflineSwitches}
                        totalOnlineUPS={totalOnlineUPS}
                        totalOfflineUPS={totalOfflineUPS}
                        totalServers={totalServers}
                        totalDevices={totalDevices}
                        totalAnalytics={totalAnalytics}
                        totalSwitches={totalSwitches}
                        totalUPS={totalUPS}
                      />
                  </Row>

                  {/* Port Layout */}
                  {totalOnlineSwitches?
                  <p className="view-port-btn" onClick={handleOpenPortLayout}><img src={PORT_UP_WHITE_ICON} style={{height: '1.5rem'}} alt="port-up-white-icon"/> View Port Layout</p>
                  :
                  <CustomTooltipPortLayout text = "No switches available">
                  <p className="view-port-btn-disabled"><img src={PORT_UP_WHITE_ICON} style={{height: '1.5rem'}} alt="port-up-white-icon"/> View Port Layout</p>
                  </CustomTooltipPortLayout>
                  } 

                  {/* Testing only                   */}
                  {/* <ToastContainer
                      autoClose={false}
                      stacked={true}
                      style={{width: '350px', cursor: 'pointer'}}
                    /> */}

                    {/* ///// */}


                  {/* Modal to view switches */}
                  <Modal size={sortedData.length === 1 ? 'md' : 'lg'} style={{ height: '50em'}} show={isPortLayoutVisible} onHide={handleClosePortLayout}>
                    <Modal.Header closeButton closeVariant={'white'} style={{background: '#334066', height: '3em', paddingTop: '.2em' }} >
                      <Modal.Title>
                        <span style={{ color:'white', fontSize: 'medium' }}>Switches</span>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{background: '#334066', paddingRight:0, color: 'white', height: '20rem' }}>
                    <div className="switches-container">
                      {sortedData.map((server) =>
                        (server.switchInfo !== null) &&
                          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '3rem'}}>
                            <p className="switch-site-name"><b>Site:</b> {server.site}</p>
                            <div className="switch-container">
                              <p className="switch-server-name"><b>Server:</b> {server.serverName}</p>
                                <div className="ports-container">
                                  <div className="ports-top-half">
                                    {/* top half - odd numbers */}
                                    {Array.from({length: server.switchInfo.total_ports}, (_, index) => index +1 ).filter((index) => index % 2 !== 0).map((port) =>                                    
                                      <div className="port-num-img"> 
                                        <p className="port-text">{port}</p>
                                        {/* Check if there is any connected port that exists in the port layout. If no, use black image */}
                                        {server.switchInfo.connected_ports.some(connected_port => connected_port.port_number === port ) ?
                                        // Filter out these ports. For each filterd port, check its status and update the image accordingly
                                          server.switchInfo.connected_ports.filter(connected_port => connected_port.port_number === port ).map((filtered_port) =>
                                            <Port filtered_port = {filtered_port} server = {server} user = {user} position = "top"/>
                                            )
                                        : 
                                          <img className='port-icon-unconnected' src={PORT_UP_ICON} alt="port_icon" />
                                        }
                                      </div>                      
                                    )}
                                  </div>

                                  <div className="ports-bottom-half">
                                    {/* bottom half - even numbers */}
                                    {Array.from({length: server.switchInfo.total_ports}, (_, index) => index +1 ).filter((index) => index % 2 === 0).map((port) =>                                                              
                                        <div className="port-num-img">
                                        {/* Check if there is any connected port that exists in the port layout. If no, use normal image */}
                                        {server.switchInfo.connected_ports.some(connected_port => connected_port.port_number === port ) ?
                                          // Filter out these ports. For each filterd port, check its status and update the image accordingly
                                          server.switchInfo.connected_ports.filter(connected_port => connected_port.port_number === port ).map((filtered_port) =>
                                            <Port filtered_port = {filtered_port} server = {server} user = {user} position = "bottom"/>
                                            )
                                        : 
                                          <img className='port-icon-unconnected' src={PORT_DOWN_ICON} alt="port_icon" />
                                        }
                                        <p className="port-text">{port}</p>
                                      </div>
                                      )}
                                  </div> 
                                </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Modal.Body>
                  </Modal>
                    
                  {/* cards */}
                  <Row className="health-cards-container">
                    <Col lg={4}>
                      <div className='health-cards'>
                        <Row id='health-card-container'>
                          <Col id='health-card-icon' xs={3} sm={3} lg={3}>
                            <FontAwesomeIcon icon={faBell} style={{ color: 'white'}}/>
                          </Col>
                          <Col xs={9} sm={9} lg={9}>
                            <Row>
                              <div id='health-card-title'>UNACKNOWLEDGED EVENTS</div>
                            </Row>
                            <Row>
                              <div id='health-card-content'>{unAckAlerts.length}</div>
                            </Row>
                            <Row>
                            <div id='health-card-action'>
                                <Button 
                                  id='health-card-action-btn'
                                   disabled={!unAckAlerts.length}
                                    variant='text'
                                    onClick={handleShowUnAckAlerts}>
                                    Click here to view
                                </Button>
                              </div>
                            </Row>
                          </Col>                         
                        </Row>                     
                      </div>
                    </Col>
                    
                    <Col lg={4}>
                    <div className='health-cards'>
                        <Row id='health-card-container'>
                          <Col id='health-card-icon' xs={3} sm={3} lg={3}>
                          <FontAwesomeIcon icon={faServer} style={{ color: 'white' }}/>
                          </Col>
                          <Col xs={9} sm={9} lg={9}>
                            <Row>
                              <div id='health-card-title'>SERVERS OFFLINE IN PAST WEEK</div>
                            </Row>
                            <Row>
                              <div id='health-card-content'>{pastWeekOfflineServers.length}</div>
                            </Row>
                            <Row>
                              <div id='health-card-action'>
                                <Button 
                                  id='health-card-action-btn'
                                   disabled={!pastWeekOfflineServers.length}
                                    variant='text'
                                    onClick={handleShowPastWkOfflineServer}>
                                    Click here to view
                                </Button>
                              </div>
                            </Row>
                          </Col>                         
                        </Row>                     
                      </div>
                    </Col>

                    <Col lg={4}>
                    <div className='health-cards'>
                        <Row id='health-card-container'>
                          <Col id='health-card-icon' xs={3} sm={3} lg={3}>
                          <FontAwesomeIcon icon={faVideoCamera} style={{ color: 'white' }}/>
                          </Col>
                          <Col xs={9} sm={9} lg={9}>
                            <Row>
                              <div id='health-card-title'>CAMERAS OFFLINE IN PAST WEEK</div>
                            </Row>
                            <Row>
                              <div id='health-card-content'>{pastWeekOfflineDevices().length}</div>
                            </Row>
                            <Row>
                            <div id='health-card-action'><Button id='health-card-action-btn'  disabled={!pastWeekOfflineDevices().length} variant='text' onClick={handleShowPastWkOfflineDevices}>Click here to view</Button></div>
                            </Row>
                          </Col>                         
                        </Row>                     
                      </div>
                    </Col>
                  </Row>

                  {/* Server alert table starts  */}
                  {sortedDataAlerts.length ?
                  <>
                  <div className='alert-table-container'>                   
                      <span className='health-info-alerts-text'>Server Alerts</span>                   
                      <FontAwesomeIcon icon={faBell} className='health-info-alerts-icon'/>
                      {(totalOfflineServers !== 0) && <div className="bell-icon-badge-alert-table">{totalOfflineServers}</div>}
                  </div>
                  <Row>                  
                    <div style={{ padding: '1em' }}>
                      <div className='table-container'>
                        <table id='server-table'>    
                          <thead>
                            <tr>          
                            <th onClick={() => handleSortAlert('site')}>
                                Site Name {renderSortIconAlert('site')}
                              </th>
                              <th onClick={() => handleSortAlert('si')}>
                                SI Name {renderSortIconAlert('si')}
                              </th>
                              <th onClick={() => handleSortAlert('serverName')}>
                                Server Name {renderSortIconAlert('serverName')}
                              </th>
                              <th onClick={() => handleSortAlert('serverStatus')}>
                                Server Status {renderSortIconAlert('serverStatus')}
                              </th>
                              <th onClick={() => handleSortAlert('serverOnlineDays')}>
                                Activity Time {renderSortIconAlert('serverOnlineDays')}
                              </th>
                              <th onClick={() => handleSortAlert('serverOfflineSince')}>
                                Server Offline Since {renderSortIconAlert('serverOfflineSince')}
                              </th>
                              <th onClick={() => handleSortAlert('totalDevices')}>
                                Cameras - Total / Offline {renderSortIconAlert('totalDevices')}
                              </th>
                              <th><span>Acknowledge Status</span></th>
                            </tr>
                          </thead>
                          <tbody>
                            {sortedAllColumnsAlerts.map((server, index) => (                          
                                <Server 
                                  key={index}
                                  server={server} 
                                  isAlert={true}
                                  isUnAck={false}                                 
                                />
                            ))}
                          </tbody>
                          </table> 
                      </div>
                    </div>
                  </Row>
                  </>
                  : 
                  <></>
                  }
                   {/* Server alert table ends  */}
                  
                  {/* Analytic alerts starts  */}                  
                  {totalOfflineAnalytics ?
                  <>
                    <div className='alert-table-container'>                   
                      <span className='health-info-alerts-text'>Analytic Alerts</span>                   
                      <FontAwesomeIcon icon={faBell} className='health-info-alerts-icon'/>
                      {(totalOfflineAnalytics !== 0) && <div className="bell-icon-badge-alert-table">{totalOfflineAnalytics}</div>}
                    </div>
                  <Row>
                    <div style={{ padding: '1em' }}>        
                        <div className='table-container'>
                          <table id='server-table'>    
                            <thead>
                              <tr>          
                                <th>Site Name</th>                                                         
                                <th>Analytic Name</th>
                                <th>Activity Time</th>
                                <th>Analytic Offline Since</th>
                                <th>Analytic Status</th>                                 
                              </tr>
                            </thead>
                            <tbody>
                              { sortedData.map((server, index) => (
                                  <AnalyticInfo
                                  key = {index}
                                  server = {server}
                                  />
                              ))}
                            </tbody>
                            </table> 
                        </div>
                      </div>
                  </Row>
                  </>
                  :
                  <></>
                  }
                  {/* Analytic alerts ends  */}

                  {/* UPS alerts starts  */}
                  { alertAdditionalDevices() ?
                  <>
                  <div className='alert-table-container'>                   
                      <span className='health-info-alerts-text'>UPS Alerts</span>                   
                      <FontAwesomeIcon icon={faBell} className='health-info-alerts-icon'/>
                      {(totalOfflineUPS !== 0) && <div className="bell-icon-badge-alert-table">{totalOfflineUPS}</div>}
                  </div>  
                  <Row>
                    <div style={{ padding: '1em' }}>
                        <div className='table-container'>
                          <table id='server-table'>    
                            <thead>
                              <tr>          
                                <th>Site Name</th>                                                         
                                <th>Device Type</th>
                                <th>Device Label</th>
                                <th>Activity Time</th>
                                <th>Device Offline Since</th>
                                <th>Device Status</th>                                 
                              </tr>
                            </thead>
                            <tbody>
                              {sortedData.map((server, index) => (                          
                                  <AdditionalInfo 
                                    key={index}
                                    server={server} 
                                  />                          
                              ))}
                            </tbody>
                            </table> 
                        </div>
                      </div>
                  </Row>
                  </>
                  :
                  <></>
                  }                  
                  {/* UPS alerts ends  */}
              
                  {/* Switch alerts starts  */}
                  {/* <div className='alert-table-container'>                   
                      <span className='health-info-alerts-text'>Switch Alerts - In progress</span>                   
                      <FontAwesomeIcon icon={faBell} className='health-info-alerts-icon'/>              
                  </div> */}
                  {totalOfflineSwitches ?
                  <>
                  <div className='alert-table-container'>                   
                      <span className='health-info-alerts-text'>Switch Alerts</span>                   
                      <FontAwesomeIcon icon={faBell} className='health-info-alerts-icon'/>
                      {(totalOfflineSwitches !== 0) && <div className="bell-icon-badge-alert-table">{totalOfflineSwitches}</div>}
                  </div>                     
                  <Row>
                    <div style={{ padding: '1em' }}> 
                      <div className='table-container'>
                        <table id='server-table'>    
                          <thead>
                            <tr>          
                              <th>Site Name</th>
                              <th>Server Name</th>
                              <th>Switch ID</th>                            
                              <th>Total Ports</th>
                              <th>Activity Time</th>
                              <th>Switch Offline Since</th>
                              <th>Switch Status</th>                             
                            </tr>
                          </thead>
                          <tbody>
                            {sortedData.map((server, index) => (
                              (server.switchInfo !== null) && 
                                <Switch 
                                  key={index}
                                  server={server}
                                />                          
                            ))}
                          </tbody>
                          </table> 
                      </div>  
                    </div>
                  </Row>
                  </>
                  :
                  <></>
                  }         
                  {/* Switch alerts ends  */}

                  {/* Storage data starts  */}
                  {alertStorage() ?
                  <>
                  <div className='alert-table-container'>                   
                      <span className='health-info-alerts-text'>Storage Alerts</span>                   
                      <FontAwesomeIcon icon={faBell} className='health-info-alerts-icon'/>
                      {(totalOfflineStorages !== 0) && <div className="bell-icon-badge-alert-table">{totalOfflineStorages}</div>}
                  </div>                     
                  <Row>
                    <div style={{ padding: '1em' }}> 
                      <div className='table-container'>
                        <table id='server-table'>    
                          <thead>
                            <tr>          
                              <th>Site Name</th>
                              <th>Server Name</th>                            
                              <th>Storage ID</th>
                              <th>Activity Time</th>
                              <th>Storage Offline Since</th>
                              <th>Storage Status</th>                               
                            </tr>
                          </thead>
                          <tbody>
                            {sortedData.map((server, index) => (                          
                                <Storage 
                                  key={index}
                                  server={server}
                                />                          
                            ))}
                          </tbody>
                          </table> 
                      </div>  
                    </div>
                  </Row>
                  </>
                  :
                  <></>
                  }         
                  {/* Storage data ends  */}

                  <br></br>

                 {/* Healthy sites starts  */}
                 <span className='bottom-toggles'>Healthy Servers</span>
                  <Button variant='text'style={{ color: 'white' }} onClick={toggleHealthTableVisibility}>
                    {isHealthTableVisible ? 
                      <FontAwesomeIcon icon="fa-solid fa-angles-right" className='bottom-toggles-arrow'/> :
                      <FontAwesomeIcon icon="fa-solid fa-angles-down" className='bottom-toggles-arrow' />
                    }
                  </Button>
                  {isHealthTableVisible && (
                  <Row>
                    <div style={{ padding: '1em' }}>
                      { sortedDataHealthy.length ? (
                       <div className='table-container'>
                        <table id='server-table'>
                          <thead>
                            <tr>
                              <th onClick={() => handleSortHealthy('site')}>
                                Site Name {renderSortIconHealthy('site')}
                              </th>
                              <th onClick={() => handleSortHealthy('si')}>
                                SI Name {renderSortIconHealthy('si')}
                              </th>
                              <th onClick={() => handleSortHealthy('serverName')}>
                                Server Name {renderSortIconHealthy('serverName')}
                              </th>
                              <th onClick={() => handleSortHealthy('serverStatus')}>
                                Server Status {renderSortIconHealthy('serverStatus')}
                              </th>
                              <th onClick={() => handleSortHealthy('serverOnlineDays')}>
                                Activity Time {renderSortIconHealthy('serverOnlineDays')}
                              </th>
                              <th onClick={() => handleSortHealthy('serverOfflineSince')}>
                                Server Offline Since {renderSortIconHealthy('serverOfflineSince')}
                              </th>
                              <th onClick={() => handleSortHealthy('totalDevices')}>
                                Cameras - Total / Offline {renderSortIconHealthy('totalDevices')}
                              </th>
                              <th>
                                Cameras - Details
                              </th>
                            </tr>
                          </thead>
                         <tbody>
                           {sortedAllColumnsHealthy.map((server, index) => (
                             <Server
                               key={index}
                               server={server}
                               isAlert={false}
                               isUnAck={false}
                               selectedBusiness={selectedBusiness}
                             />
                           ))}
                         </tbody>
                       </table>
                     </div>
                      )
                      :
                       (totalServers ? 
                          <span className='healthy-sites-msg' >No site is healthy.</span>
                        : 
                          <span className='no-data-avail-msg' >No server data available.</span>)
                      }
                  </div>
                  </Row>
                  )}
                  {/* Healthy sites ends  */}

                  {/* Healthy analytics starts  */}
                  <span className='bottom-toggles'>Healthy Analytics</span>
                  <Button variant='text'style={{ color: 'white' }} onClick={toggleHealthyAnalyticsTableVisibility}>
                    {isHealthyAnalyticsTableVisible ? 
                      <FontAwesomeIcon icon="fa-solid fa-angles-right" className='bottom-toggles-arrow'/> :
                      <FontAwesomeIcon icon="fa-solid fa-angles-down" className='bottom-toggles-arrow'/>
                    }
                  </Button>

                  {isHealthyAnalyticsTableVisible && (
                  <Row>
                    <div style={{ padding: '1em' }}>
                      { totalOnlineAnalytics > 0 ? 
                        (<div className='table-container'>
                          <table id='server-table'>
                            <thead>
                              <tr>          
                                <th>Site Name</th>                                                         
                                <th>Analytic Name</th>
                                <th>Activity Time</th>
                                <th>Analytic Offline Since</th>
                                <th>Analytic Status</th>                                 
                              </tr>
                            </thead>
                          <tbody>
                            {sortedData.map((server, index) => (
                              <AnalyticInfo
                                key={index}
                                server={server}
                                isHealthy={true}
                              />
                            ))}
                          </tbody>
                        </table>
                      </div>)   
                      :
                        (totalAnalytics ?
                          <span className='healthy-sites-msg'>No analytic is healthy.</span>
                        :
                          <span className='no-data-avail-msg'>No analytics data available.</span>)
                      }
                  </div>
                  </Row>
                  )}
                  {/* Healthy analytics ends  */}

                  {/* Healthy UPS starts  */}
                  <span className='bottom-toggles'>Healthy UPS</span>
                  <Button variant='text'style={{ color: 'white' }} onClick={toggleHealthyUPSTableVisibility}>
                    {isHealthyUPSTableVisible ? 
                      <FontAwesomeIcon icon="fa-solid fa-angles-right" className='bottom-toggles-arrow'/> :
                      <FontAwesomeIcon icon="fa-solid fa-angles-down" className='bottom-toggles-arrow' />
                    }
                  </Button>

                  {isHealthyUPSTableVisible && (
                  <Row>
                    <div style={{ padding: '1em' }}>
                      { totalOnlineUPS > 0 ? (
                       <div className='table-container'>
                        <table id='server-table'>
                          <thead>
                            <tr>          
                              <th>Site Name</th>                                                         
                              <th>Device Type</th>
                              <th>Device Label</th>
                              <th>Activity Time</th>
                              <th>Device Offline Since</th>
                              <th>Device Status</th>                                 
                            </tr>
                          </thead>
                         <tbody>
                           {sortedData.map((server, index) => (
                             <AdditionalInfo
                               key={index}
                               server={server}
                               isHealthy={true}
                             />
                           ))}
                         </tbody>
                       </table>
                     </div>
                      )   
                      :
                        (totalUPS ?
                          <span className='healthy-sites-msg' >No UPS is healthy.</span>
                        :
                          <span className='no-data-avail-msg'>No UPS data available.</span>)
                      }
                  </div>
                  </Row>
                  )}
                  {/* Healthy UPS ends  */}

                  {/* Healthy Switches starts  */}
                  <span className='bottom-toggles'>Healthy Switches</span>
                  <Button variant='text'style={{ color: 'white' }} onClick={toggleHealthySwitchesTableVisibility}>
                    {isHealthySwitchesTableVisible ? 
                      <FontAwesomeIcon icon="fa-solid fa-angles-right" className='bottom-toggles-arrow'/> :
                      <FontAwesomeIcon icon="fa-solid fa-angles-down" className='bottom-toggles-arrow' />
                    }
                  </Button>
                  
                  {isHealthySwitchesTableVisible && (
                    <Row>
                    <div style={{ padding: '1em' }}>
                      {totalOnlineSwitches > 0 ? (
                      <div className='table-container'>
                        <table id='server-table'>    
                          <thead>
                            <tr>          
                              <th>Site Name</th>
                              <th>Server Name</th>
                              <th>Switch ID</th>                            
                              <th>Total Ports</th>
                              <th>Activity Time</th>
                              <th>Switch Offline Since</th>
                              <th>Switch Status</th>                             
                            </tr>
                          </thead>
                          <tbody>
                            {sortedData.map((server, index) => (
                              (server.switchInfo !== null) &&                   
                                <Switch 
                                  key={index}
                                  server={server}
                                  isHealthy={true}
                                />                          
                            ))}
                          </tbody>
                          </table> 
                      </div> )
                      :
                      (totalSwitches ?
                        <span className='healthy-sites-msg' >No Switch is healthy.</span>
                        :
                        <span className='no-data-avail-msg'>No Switch data available.</span>)
                      } 
                    </div>
                  </Row>
                  )}
                  {/* Healthy Switches ends  */}
                  

                  {/* Healthy Storage starts  */}                
                  <span className='bottom-toggles'>Healthy Storages</span>
                  <Button variant='text'style={{ color: 'white' }} onClick={toggleStorageTableVisibility}>
                    {isStorageTableVisible ? 
                      <FontAwesomeIcon icon="fa-solid fa-angles-right" className='bottom-toggles-arrow'/> :
                      <FontAwesomeIcon icon="fa-solid fa-angles-down" className='bottom-toggles-arrow' />
                    }
                  </Button>
                  {isStorageTableVisible && (
                  <Row>
                    <div style={{ padding: '1em' }}>
                      {totalStorages ?
                        <div className='table-container'>
                          <table id='server-table'>    
                            <thead>
                              <tr>          
                                <th>Site Name</th>
                                <th>Server Name</th>                            
                                <th>Storage ID</th>
                                <th>Activity Time</th>
                                <th>Storage Offline Since</th>
                                <th>Storage Status</th>                               
                              </tr>
                            </thead>
                            <tbody>
                              {sortedData.map((server, index) => (                          
                                  <Storage 
                                    key={index}
                                    server={server}
                                    isHealthy={true}
                                  />                          
                              ))}
                            </tbody>
                            </table> 
                        </div>
                      : <span className="no-data-avail-msg">No storage data available.</span>
                      }
                    </div>
                  </Row>                  
                  )}
                  {/* Healthy Storage ends  */}

                {/* Map starts */}
                </div>
                  {                    
                    sortedDataByColor.length ?
                    (
                    <MapContainer
                      ref={map => {mapRef.current = map; setMapRefLoaded(true);}}
                      center={center}
                      zoom={5}
                      scrollWheelZoom={true}
                      style={{height: "600px", width: "100%", borderRadius: '0.3rem'}}
                      >
                      <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          
                      <LayersControl position="topright">
                        <BaseLayer checked name="Map">
                          {/* Base layer */}
                          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        </BaseLayer>


                        {/* Overlay layers */}
                        <Overlay checked name='Red Markers - Offline Servers'>
                          <LayerGroup>
                            {sortedDataByColor
                              .filter(each => each.color === 'red')
                              .map(each => (
                                (each.servers[0]?.latitude &&  each.servers[0]?.longitude) &&
                                <Marker 
                                  key={each.site}
                                  position={[each.servers[0]?.latitude, each.servers[0]?.longitude]}
                                  icon={redIcon}
                                  zIndexOffset={1000}
                                  eventHandlers={{
                                    mouseover: () => {
                                      setHoveredMarker(each.site); // Set the hovered marker
                                    },
                                    mouseout: () => {
                                      setHoveredMarker(null); // Clear the hovered marker
                                    }
                                  }}
                                >
                                  {/* Tooltip for hover */}
                                  <Tooltip>
                                    <div>
                                      <span className='map-sitename-label'>Business:</span> {each.servers[0]?.business}&nbsp;|&nbsp;
                                      <span className='map-sitename-label'>Site:</span> {each.site}
                                      <table className='table-container-map'>
                                        <tbody>
                                          <tr>
                                            <th>Server Name</th>
                                            <th>Server Status</th>
                                            <th>Offline Since</th>
                                            <th>Total Cameras</th>
                                          </tr>
                                          {each.servers.map(server => (
                                            <tr key={server.serverName}>
                                              <td>{server.serverName}</td>
                                              <td>{server.serverStatus}</td>
                                              <td>{utcStrToLocal(server.serverOfflineSince)}</td>
                                              <td>{server.totalDevices}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </Tooltip>                                      
                                </Marker>
                                  ))}
                              </LayerGroup>
                            </Overlay>

                         {/* Overlay layers */}
                         <Overlay checked name="Orange Markers - Offline Cameras">
                          <LayerGroup>
                            {sortedDataByColor
                              .filter(each => each.color === 'orange')
                              .map(each => (
                                (each.servers[0]?.latitude &&  each.servers[0]?.longitude) &&
                                <Marker 
                                key={each.site}
                                position={[each.servers[0]?.latitude, each.servers[0]?.longitude]}
                                icon={orangeIcon}
                                zIndexOffset={500}
                                eventHandlers={{
                                  mouseover: () => {
                                    setHoveredMarker(each.site); // Set the hovered marker
                                  },
                                  mouseout: () => {
                                    setHoveredMarker(null); // Clear the hovered marker
                                  }
                                }}
                                >
                                  <Tooltip>
                                    <div>
                                    <span className='map-sitename-label'>Business:</span> {each.servers[0]?.business}&nbsp;|&nbsp;
                                      <span className='map-sitename-label'>Site name:</span> {each.site}
                                      <table className='table-container-map'>
                                        <tbody>
                                          <tr>
                                            <th>Server Name</th>
                                            <th>Server Status</th>
                                            <th>Total Cameras</th>
                                            <th>Offline Cameras</th>
                                          </tr>
                                          {each.servers.map(server => (
                                            <tr key={server.serverName}>
                                              <td>{server.serverName}</td>
                                              <td>{server.serverStatus}</td>
                                              <td>{server.totalDevices}</td>
                                              <td>{server.devicesOffline}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </Tooltip>
                                </Marker>
                              ))}
                          </LayerGroup>
                        </Overlay>

                         {/* Overlay layers */}
                         <Overlay checked name="Green Markers - All Healthy">
                          <LayerGroup>
                            {sortedDataByColor
                              .filter(each => each.color === 'green')
                              .map(each => (
                                (each.servers[0]?.latitude &&  each.servers[0]?.longitude) &&
                                <Marker 
                                  key={each.site}
                                  position={[each.servers[0]?.latitude, each.servers[0]?.longitude]}
                                  icon={greenIcon}
                                  zIndexOffset={0}
                                  eventHandlers={{
                                    mouseover: () => {
                                      setHoveredMarker(each.site); // Set the hovered marker
                                    },
                                    mouseout: () => {
                                      setHoveredMarker(null); // Clear the hovered marker
                                    }
                                  }}
                                >
                                  
                                  <Tooltip>
                                    <div>
                                    <span className='map-sitename-label'>Business:</span> {each.servers[0]?.business}&nbsp;|&nbsp;
                                      <span className='map-sitename-label'>Site name:</span> {each.site}
                                      <table className='table-container-map'>
                                        <tbody>
                                          <tr>
                                            <th>Server_Name</th>
                                            <th>Server Status</th>
                                            <th>Total Cameras</th>
                                            
                                          </tr>
                                          {each.servers.map(server => (
                                            <tr key={server.serverName}>
                                              <td>{server.serverName}</td>
                                              <td>{server.serverStatus}</td>
                                              <td>{server.totalDevices}</td>
                                            
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </Tooltip>
                                </Marker>
                              ))}
                          </LayerGroup>
                        </Overlay>

                      </LayersControl>
                    </MapContainer>

                    ) : null
                  }

                  </>
                  :
                  noServersData ?
                    <div style={{ color: '#e08a20', height: !isSmallScreen ? '25rem' : '1rem'  }}>No data found !</div>
                  :
                  <div className='loading-select-business'>
                     <RingLoader size={ !isSmallScreen ? 200 : 100} color="#F49523"/>
                  </div>
                }

                   {/* side bar cards for small screens  */}
                   {isSmallScreen && !loading ? (
                      <SideBarSmallScreen/>
                    ) : null
                  }
                </Container>        
          


          {/* Past week offline server modal */}
          <Modal centered style={{ height: '50em'}} show={showPastWkOfflineServer} onHide={handleClosePastWkOfflineServer}>
            <Modal.Header  style={{background: '#071d2c', padding: 0 }} className="modal-header">
              <Modal.Title style={{ width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#fafafa', fontSize: '0.8em', padding: '0 0.5em' }}>
                  <div >Offline Servers Past Week</div>
                  <button title="close" className='modal-close' onClick={() => handleClosePastWkOfflineServer()} >
                    <span>X</span>
                  </button>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{background: '#071d2c' }}>
              < OfflineServersPastWeek servers={pastWeekOfflineServers}/>
            </Modal.Body>
          </Modal>

          {/* Past week offline cameras modal */}
          <Modal centered style={{ height: '50em'}} show={showPastWkOfflineDevices} onHide={handleClosePastWkOfflineDevices}>
            <Modal.Header  style={{background: '#071d2c', padding: 0 }}  className="modal-header">
              <Modal.Title style={{ width: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#fafafa', fontSize: '0.8em', padding: '0 0.5em' }}>
                <div >Offline Cameras Past Week</div>
                <button title="close" className='modal-close' onClick={() => handleClosePastWkOfflineDevices()} >
                  <span>X</span>
                </button>
               </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{background: '#071d2c', paddingRight:0 }}>
              <OfflineDevicesPastWeek devices={pastWeekOfflineDevices()}/>
            </Modal.Body>
          </Modal>

          {/* Unacknowledged alerts modal */}
          <Modal size='lg' centered style={{ height: '55em'}}   show={showUnAckAlerts} onHide={handleCloseUnAckAlerts}>
            <Modal.Header  style={{ background: '#071d2c', padding: 0 }} className="modal-header">
              <Modal.Title style={{ width: '100%' }}>
               <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#fafafa', fontSize: '0.8em', padding: '0 0.5em' }}>
                <div >Unacknowledged Alerts</div>
                <button title="close" className='modal-close' onClick={() => handleCloseUnAckAlerts()} >
                  <span>X</span>
                </button>
               </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{background: '#071d2c', paddingRight:0 }}>
              <UnAckAlerts unAckAlerts={unAckAlerts}/>
            </Modal.Body>
          </Modal>
          <div className='health-info-footer-container'>
            <Footer />
          </div>
        </div>
        : <Home />
        }
      </>
) 
}



export default Servers