import * as Actions from '../constant/ActionTypes';

const serverReducers = (serversData={servers: [], code: 500}, action) => {
    switch(action.type) {
        case Actions.LIST_SERVERS:
            return action.payload;

        default :
        return serversData;
    }
}
export default serverReducers;