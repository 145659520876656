import * as Actions from '../constant/ActionTypes';

const optEmailReducers = (opt={responseCode: 500}, action) => {
    switch(action.type) {
        case Actions.OPT_EMAIL:
            return action.payload;

        default :
        return opt;
    }
}
export default optEmailReducers;