import * as Actions from "../constant/ActionTypes";
import * as api from '../../api';

export const listDevices = (serverName, user, site) => async (dispatch) => {
    try {
        const { data } = await api.listDevices({serverName: serverName, user: user, site: site});

        dispatch({
            type: Actions.LIST_DEVICES,
            payload: data
        })
    } catch (error) {
        console.log(error);
    }
}