import { useState, useEffect, useMemo } from "react";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BeatLoader } from 'react-spinners';
import { useToasts } from 'react-toast-notifications';

// local imports
import * as api from '../../../api';
import { policyNames } from '../../../config';

import { listLicenses } from '../../../redux/actions/licenses/licenseActions';
import { businessTokenMapping } from '../../../redux/actions/businessTokenMappingActions';

import { useDispatch } from 'react-redux';


// const policyNames = [
//     '1-Channel Samurai Count',
//     '2-Channel Samurai Count',
//     '4-Channel Samurai Count',
//     '1-Channel Samurai Detect',
//     '2-Channel Samurai Detect',
//     '4-Channel Samurai Detect',
//     '6-Channel Health Monitoring',
//     '50-Channel Health Monitoring',
//     '100-Channel Health Monitoring',
//     '4-Channel Parking Management',
//     '2-Channel Parking Management',
//     '4-Channel Corsight',
//     '2-channel Object',
//     '50-Channel Health Monitoring Demo',
//     '4-Channel Samurai Count Demo',
//     '4-Channel Samurai Detect Demo'
//   ];

  const licenseQty = [2, 3, 4, 5, 6, 7, 8, 9, 10];



const LicenseCreateForm = ({ licenses, fetchUserResponse, businessTokenData }) => {

    const [email, setEmail] = useState('');
    const [isNewEmail, setIsNewEmail] = useState(false);
    const [businessName, setBusinessName] = useState('');
    const [isNewBusiness , setIsNewBusiness] = useState(false);
    const [businesses, setBusinesses] = useState([]);
    const [siteName, setSiteName] = useState('');
    const [selectedPolicy, setSelectedPolicy] = useState('');
    const [selectedQty, setSelectedQty] = useState(1);
    const [deviceFingerprint, setDeviceFingerprint] = useState('');
    const [loading, setLoading] = useState(false);
    const [newLicneses, setNewLicenses] = useState(false);
    const [isOffline, setIsOffline] = useState(false);
    const [isRecipientisUser, setIsRecipientisUser] = useState(false);
    const [emailRecipient, setEmailRecipient] = useState('');

    const businessTokenMappingData = businessTokenData;

    const dispatch = useDispatch();

    const { addToast } = useToasts();

    const signedInUser = localStorage.getItem('email');   

    // get businessess from the businessTokenMappingData
    const allowedBusinesses = businessTokenMappingData?.map(item => item?.business);

  // get user role
  const userRole = fetchUserResponse?.role;

// fetch unique businesses from the lisense data and filter out allowed businesses with the user role
// wrap the initialization of 'uniqueBusinesses' in its own useMemo() hook
// to prevent it from being recalculated on every render
const uniqueBusinesses = useMemo(() => {
  let uniqueBusinesses = [...new Set(licenses?.map(license => userRole === 'L1' ? license.business : allowedBusinesses.includes(license.business) ? license.business : null))];
  // update uniqueBusinesses if not blank
  uniqueBusinesses = uniqueBusinesses.filter(item => item !== null && item !== '');
  // sort uniqueBusinesses alphabetically
  uniqueBusinesses = uniqueBusinesses.sort();
  return uniqueBusinesses;
}, [licenses, userRole, allowedBusinesses]);

//let uniqueBusinesses = [...new Set(licenses?.map(license => userRole === 'L1' ? license.business : allowedBusinesses.includes(license.business) ? license.business : null))];

// update uniqueBusinesses if not blank
//uniqueBusinesses = uniqueBusinesses.filter(item => item !== null && item !== '');

// sort uniqueBusinesses alphabetically
//uniqueBusinesses = uniqueBusinesses.sort();


  // filter by business
  let filteredByBusiness = licenses.filter(
      license => license.business === businessName
    );

  // update filteredByBusiness if the businesses are allowed
  filteredByBusiness = filteredByBusiness.filter(license => userRole === 'L1' ? license : allowedBusinesses.includes(license.business) ? license : null);



  // update license.business if it is not blank and remove them
  filteredByBusiness = filteredByBusiness.filter(item => item.business !== null && item.business !== '');

  // Fetch unique users from the filtered business list
  let uniqueUsers = [...new Set(filteredByBusiness.map((license) => license.user))];

  // sort uniqueUsers alphabetically
    uniqueUsers = uniqueUsers.sort();
   
   const constructLicenseName = (selectedPolicy, businessName, siteName) => {
    let constructedLicenseName = '';
    let channelNum = selectedPolicy.split('-')[0];
    let channelStr = `${channelNum}CH`;
    let typeArray = selectedPolicy.split(' ');
    let type = typeArray[1];
    if (typeArray.length > 2) {
        type =  typeArray[1] + '_' + typeArray[2];
    } 

    constructedLicenseName = `${businessName.toUpperCase()}-${siteName.toUpperCase()}-${channelStr}-${type}`;
    return constructedLicenseName;
   }

    const handleSubmit = async(e) => {
        e.preventDefault();
        setLoading(true);

        setNewLicenses(false);            
        localStorage.setItem('newLicenses', false);

        const constructedLicenseName = constructLicenseName(selectedPolicy, businessName, siteName);
        
        let responseKeyData = [];

        // create online license keys
        try {
            const newLicense = {
                email: email,
                licenseName: constructedLicenseName,
                licenseType: selectedPolicy,
                licenseQty: selectedQty,
                emailRecipient: emailRecipient,
                businessName: businessName,
                siteName: siteName,
                selectedPolicy: selectedPolicy,
                isOffline: isOffline,
                isNewBusiness: isNewBusiness,
                isNewUser: isNewBusiness, // set isNewEmail to true if isNewBusiness is true
                eventCreator: signedInUser // signed in user' email
            }

            const { data } = await api.API.post('/licenses', newLicense);

            if (data.length) {
                setNewLicenses(true);            
                localStorage.setItem('newLicenses', true);
                responseKeyData = data;

                // dispatch businessTokenMapping action
                dispatch(businessTokenMapping({user: signedInUser}));
            } else {
                const errorMsg = 'Error creating online licenses. Please try again later.';
                addToast(errorMsg, {
                    appearance: 'error',
                    autoDismiss: true,
                    autoDismissTimeout: 9000            
                  });
            }

            setLoading(false);
        }
        catch (error) {
            console.error('Error creating online linceses:', error);
        };
        
        // create offline license keys
        try {
            if (isOffline) {
                if (responseKeyData.length) {
                    const newOfflineLicense = {
                        keys: responseKeyData,
                        deviceFingerprint: deviceFingerprint,
                        username: email,
                        licenseName: constructedLicenseName,
                        licenseType: selectedPolicy,
                        licenseQty: selectedQty,
                        emailRecipient: emailRecipient,
                        businessName: businessName,
                        siteName: siteName,
                        selectedPolicy: selectedPolicy
                    }
                    await api.API.post('/offline_licenses', newOfflineLicense);
                }
            }
        } catch (error) {
            console.error('Error creating offline lincese:', error);
        }       
    }


    const handleClear = (e) => {
        e.preventDefault();

        setEmail('');
        setBusinessName('');
        setSiteName('');
        setSelectedPolicy('');
        setNewLicenses(false);
        setSelectedQty(1);
        setEmailRecipient('');
        setIsRecipientisUser(false);
        setIsOffline(false);
    }

    const handleChangeBusinessName = (e) => {
        let enteredBusinessName = e.target.value;
        // convert enteredBusinessName to uppercase
        enteredBusinessName = enteredBusinessName?.toUpperCase();
        setBusinessName(enteredBusinessName);
    }

    const handleCheckBox = (e) => {
        setIsOffline(e.target.checked);
    }

    const handleCheckBoxUser = (e) => {
        setIsRecipientisUser(e.target.checked);
    }

    const handleCheckBoxBusiness = (e) => {
        setIsNewBusiness(e.target.checked);
    }


    const handleCheckBoxEmail = (e) => {
        setIsNewEmail(e.target.checked);
    }

    
    useEffect(() => {
        if (isRecipientisUser) {
            if (email === '') {
                alert('Please select a user first');
            }
            setEmailRecipient(email);
        }
    }, [isRecipientisUser, email]);

    // fetch list of licenses after creating a new license
    useEffect(() => {
        if (newLicneses) {
            dispatch(listLicenses());
        }
    }, [newLicneses, dispatch]);




  let selectUserPlaceholderText = 'Enter email to tie license with';

  if (businessName === '') {
    selectUserPlaceholderText = 'Select a business first';
}

   
    return (
        <Form autoComplete="off" onSubmit={handleSubmit} style={{fontSize: 'small' }}> 
            <Container>
                <Row>
                    <Col xs={12} lg={6}>
                        <Form.Group className="mb-2">
                            <div className="create-licnese-form-recipient-toggle-label-container">
                                <Form.Label>*Select Business</Form.Label>
                                {/* <Form.Check 
                                    type="switch"
                                    className="custom-switch"
                                    label="New business"
                                    onChange={handleCheckBoxBusiness}
                                    checked={isNewBusiness}
                                /> */}
                            </div>
                        { isNewBusiness ?
                            <Form.Control
                                size="sm"
                                type="text"
                                placeholder="Enter business Name"
                                required
                                value={businessName}
                                onChange={ handleChangeBusinessName }
                            />
                            :
                            <Form.Control
                                size="sm"
                                as="select"
                                required
                                value={businessName}
                                onChange={(e) => setBusinessName(e.target.value)}
                            >
                                <option value="">Select a business</option>
                                {uniqueBusinesses.map((business, index) => (
                                    <option key={index} value={business}>
                                        {business}
                                    </option>
                                ))}
                            </Form.Control>
                        }
                                                       
                        </Form.Group>
                    </Col>
                    <Col xs={12} lg={6}>
                    <Form.Group className="mb-2">
                        <Form.Label>*Site Name</Form.Label>
                        <Form.Control 
                            size="sm"
                            type="text"
                            placeholder="Enter Site Name"
                            required
                            value={siteName}
                            onChange={(e) => setSiteName(e.target.value)}/>
                    </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={6}>
                        <Form.Group className="mb-2">
                            <div className="create-licnese-form-recipient-toggle-label-container">                        
                                <Form.Label>*Select User</Form.Label>
                                <Form.Check 
                                        type="switch"
                                        className="custom-switch"
                                        label="New user"
                                        onChange={handleCheckBoxEmail}
                                        checked={isNewEmail}
                                    />
                            </div>
                            
                            { isNewEmail ?
                                <Form.Control
                                    size="sm"
                                    type="email"
                                    placeholder="Enter email to tie license with"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}  
                                />
                                :
                                <Form.Control                                
                                    size="sm"
                                    as="select"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}                    
                                    >
                                    <option value="">{selectUserPlaceholderText}</option>
                                    {uniqueUsers.map((policy, index) => (
                                        <option key={index} value={policy}>
                                            {policy}
                                        </option>
                                    ))}
                                </Form.Control>
                            }                           
                           
                        </Form.Group>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Form.Group className="mb-2">
                            <Form.Label>*Select Policy</Form.Label>
                            <Form.Control
                              
                                size="sm"
                                as="select"
                                required
                                value={selectedPolicy}
                                onChange={(e) => setSelectedPolicy(e.target.value)}                    
                                >
                                <option value="">Select a policy</option>
                                {policyNames.map((policy, index) => (
                                    <option key={index} value={policy}>
                                        {policy}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row> 
                <Row>                    
                    <Col xs={12} lg={6}>
                    <Form.Group className="mb-2">
                            <Form.Label>*Select Quantity</Form.Label>
                            <Form.Control
                          
                                size="sm"
                                as="select"
                                required
                                value={selectedQty}
                                onChange={(e) => setSelectedQty(e.target.value)}                    
                                >
                                <option value={1}>1</option>
                                {licenseQty.map((qty, index) => (
                                    <option key={index} value={qty}>
                                        {qty}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
              
               
                <Col>
                <Form.Group className="mb-2">
                    <div className="create-licnese-form-recipient-toggle-label-container">
                        <Form.Label>*Recipient email</Form.Label>
                        <Form.Check 
                            type="switch"
                            className="custom-switch"
                            label="same as user"
                            onChange={handleCheckBoxUser}
                            checked={isRecipientisUser}
                        />
                    </div>
                    <Form.Control
                        size="sm"
                        type="text"
                        placeholder="Enter recipient's email"
                        required
                        value={emailRecipient}
                        onChange={(e) => setEmailRecipient(e.target.value)}
                    />
                    </Form.Group>
               </Col>
                </Row>
                <Row>
                    <Col>
                    <Form.Group className="mb-2">
                        <Form.Check
                            type="switch"
                            className="custom-switch"
                            label="Offline License"
                            onChange={handleCheckBox}
                            checked={isOffline}
                        />
                        { isOffline &&
                        <>
                            <Form.Label>*Device Fingerprint</Form.Label>
                            <Form.Control 
                                size="sm"
                                type="text"
                                placeholder="Enter deivce fingerprint"
                                required
                                value={deviceFingerprint}
                                onChange={(e) => setDeviceFingerprint(e.target.value)}
                            />
                        </>
                        
                        }
                        </Form.Group>
                    </Col>
                   
                </Row>
                
                <div>               
                    <button type="submit" className="create-license-form-submit-btn">
                        Submit
                    </button>                
                </div>
            
                <div className="create-license-form-clear-btn-container">          
                    <button onClick={handleClear} className="create-license-form-clear-btn">
                        Clear
                    </button>                
                </div>

            { (loading && !newLicneses) &&
            <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'center' }}>
                <BeatLoader size={10}  color="black"/>
            </div>
            }
            
            <div style={{ marginTop: '1rem', textAlign: 'center' }}>
                {
                newLicneses && 
                <div className="license-create-form-footer-container">
                    <span className="license-create-form-footer-text">
                        {`Your licenses have been sent to `}
                    </span>
                    <span className="license-create-form-footer-email">{emailRecipient}</span>
                </div>
                }
            </div>
            
            </Container>
        </Form>
    )  
}

export default LicenseCreateForm;