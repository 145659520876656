import * as Actions from '../constant/ActionTypes';

const businessTokenMappingReducer = (businessTokenMappingData=[], action) => {
    switch(action.type) {
        case Actions.BUSINESS_TOKEN_MAPPING:
            return action.payload;

        default :
        return businessTokenMappingData;
    }
}
export default businessTokenMappingReducer;