import * as Actions from "../constant/ActionTypes";

// Action to add the port-cycle outcome 
export const addPortCycleOutcome = (portData) => ({
    type: Actions.ADD_PORT_CYCLE_OUTCOME,
    payload: portData, // (portNumber, portName, portOutcome, timeTaken)
});

// Action to remove port-cycle outcome given the port_number
// export const removePortCycleOutcome = (portNumber) = ({
//     type: Actions.REMOVE_PORT_CYCLE_OUTCOME,
//     payload: portNumber, // Port number to remove
// });

// Action to remove all port-cycle outcomes
export const removeAllPortCycleOutcomes = ({
    type: Actions.REMOVE_ALL_PORT_CYCLE_OUTCOMES,
});
