import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { BeatLoader } from 'react-spinners';
// error notification box
import { useToasts } from 'react-toast-notifications';


// set up amplify config
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from '../../aws-exports';

import '../../../src/styles/login.css';

import { addUser } from '../../redux/actions/addUser';

Amplify.configure(awsconfig);

const SignUp = ({ onClose }) => {

    const [email, setEmail] =  useState('');
    const [password, setPassword] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [loadingSignup, setLoadingSignup] = useState(false);
    const [loadingConfirmSignup, setLoadingConfirmSignup] = useState(false);

    const { addToast } = useToasts();

    const dispatch = useDispatch();

    const isSmallScreen = window.innerWidth < 600;
  
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }


  async function resendConfirmationCode(username) {
    try {
        await Auth.resendSignUp(username);  
        setIsConfirmed(true);     
       
        // let errorMsg = `A confirmation code has been resent to ${username}`;
        // addToast(errorMsg, {
        //   appearance: 'error',
        //   autoDismiss: true,
        // });
       
    } catch (err) {
        console.log(err.message);
        if (err.message === 'User is already confirmed.') {
          
        let errorMsg = `User is already confirmed. You can sign in with this user.`;
        addToast(errorMsg, {
          appearance: 'error',
          autoDismiss: true,
        });

        onClose();
        }
        else {
          addToast(err.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
       
    }
}

  const handleSignUp = async (e) => {
      e.preventDefault();        
      try {
        setLoadingSignup(true); // start rendering loading icon
        await Auth.signUp({
          username: email, // Use email as the username
          password,
          attributes: {
            email,
            // phone_number: phoneNumber
          },
          autoSignIn: {
            // optional - enables auto sign in after user is confirmed
            enabled: true,
          },
        });
      
        setIsConfirmed(true);
        setLoadingSignup(false);  // stop rendering loading icon      
      } catch (error) {
        setIsConfirmed(false);
        setLoadingSignup(false);  // stop rendering loading icon

        if (error.message === 'An account with the given email already exists.') {
          setLoadingSignup(true);
          await resendConfirmationCode(email);
          setLoadingSignup(false);             
        }
      
        else {
          addToast(error.message, {
            appearance: 'error',
            autoDismiss: true,
            style: { zIndex: 9999 }
          });
        }
        
      }
    };

      const confirmSignUp = async () => {
        try {
          setLoadingConfirmSignup(true);     // start rendering loading icon     
          await Auth.confirmSignUp(email, confirmationCode); // Use email for confirmation
          setLoadingConfirmSignup(false);  // stop rendering loading icon      
          // add user data into users table
          const userData = { email: email, signup_time: new Date() }
          dispatch(addUser(userData));

          let successMsg = "Your sign up was successful. Please contact Art of Logic tech support (support@artoflogictech.com) to register your email with AoL Health Monitoring services. Pleade ignore if already registered.";
          addToast(successMsg, {
            appearance: 'success',
            autoDismiss: true,
            transitionDuration: 700,
            autoDismissTimeout: 9000
          });

          onClose();
        } catch (error) {
          setLoadingConfirmSignup(false);  // stop rendering loading icon
          addToast(error.message, {
            appearance: 'error',
            autoDismiss: true,
            style: { zIndex: 9999 }
          });
        }
      };

  return (
    <>
       
       
        {!isConfirmed ? (
          <form onSubmit={handleSignUp}>
              <div>
                <input id='input-box-signup'              
                required
                type="email"
                placeholder="Email"
                value={email}
                onChange={handleEmailChange}
                />   

                <input id='input-box-signup'
                required
                type="password"
                placeholder="Password"
                minLength={8}
                value={password}
                onChange={handlePasswordChange}
                />
                
                <button size={ isSmallScreen ? 'sm' : 'md' } className='signup-submit-btn'>Sign up</button>

                { loadingSignup ?
                  <div style={{ marginTop: '0.0em', textAlign: 'center' }}>
                    <BeatLoader size={5} color="#36d7b7"/>
                  </div> :
                  <div style={{ marginTop: '0.0em', height: '1.5em' }}>
                  
                  </div> 
                }

              </div>
              </form>

              ) :

            <div>
                  <input id='input-box-signup-confirmation'
                    type="text"
                    placeholder="Enter confirmation Code"
                    value={confirmationCode}
                    onChange={(e) => setConfirmationCode(e.target.value)}
                  />
                <div id='input-box-signup-confirmation-text' >
                  A confirmation code has been sent to <span >{email}</span>
                </div>
                <button onClick={confirmSignUp} className='signup-confirm-code-btn'>Confirm</button>

                { loadingConfirmSignup ?
                  <div style={{ marginTop: '0.0em', textAlign: 'center' }}>
                    <BeatLoader size={5} color="#36d7b7"/>
                  </div> :
                  <div style={{ marginTop: '0.0em', height: '1.5em' }}>
                  
                  </div> 
                }
            </div>
        }
        
     </>
  )
}

export default SignUp