import React from 'react';
import './cameragradedbar.css';



const GradedBar = ({ barColor }) => {
  const color = barColor;

  return (
    <div className="camera-graded-bar">
      <div
        className="camera-graded-bar-inner"
        style={{ backgroundColor: color }}
      />
    </div>
  );
};

export default GradedBar;
