
const AnalyticInfo = ({server, isHealthy}) => {
    const siteName = server?.analyticHealthData?.site;
    const analyticStatus =  server?.analyticHealthData?.anayticStatus;

    const utcStrToLocal = (utcTimestamp) => {
        // Create a Date object using the UTC timestamp
        const utcDate = new Date(utcTimestamp);
    
        // Get the local time components
        const localYear = utcDate.getFullYear();
        const localMonth = utcDate.getMonth() + 1; // Months are zero-based
        const localDay = utcDate.getDate();
        const localHours = utcDate.getHours();
        const localMinutes = utcDate.getMinutes();
        const localSeconds = utcDate.getSeconds();
        // Create a new Date object with the local time components
        const localDate = new Date(localYear, localMonth - 1, localDay, localHours, localMinutes, localSeconds);
        // Format the local time as needed
        const formattedLocalTime = localDate.toLocaleString();
        return formattedLocalTime
      }
    
    const dateTimeUTC = (datetime) => {
        let deviceOfflineSince = datetime;
        if (datetime !== 'N/A') {
            deviceOfflineSince = utcStrToLocal(datetime);
        }
        return deviceOfflineSince;
    };

    // calculate total days since the give date time given have passed
    const daysSince = (targetDateStr) => {
        // Get the current local date and time
        let currentDate = new Date().toISOString();

        // Parse the date strings into Date objects
        currentDate = new Date(currentDate);
        let targetDate = new Date(targetDateStr);    

        // Calculate the time difference in milliseconds
        const timeDifference = currentDate - targetDate;     

        // Convert milliseconds to days
        const daysDifference = timeDifference / (1000 * 60 * 60 * 24);    
        return Math.floor(daysDifference);
    }
    

    return (
        // Render healthy analytics
        isHealthy ?
            (server?.analyticHealthData?.onlineAnalytics?.map((onlineAnalytic, index) => (
                <tr key={index}>
                    <td>{siteName}</td>
                    <td>{onlineAnalytic.analytic_name}</td>
                    <td>
                        <svg  style={{ color: '#32de84' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right-circle" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.854 10.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707l-4.096 4.096z"/>
                        </svg>
                        <span style={{ color: '#32de84', marginLeft: '.2rem' }}>{onlineAnalytic.analytic_online_days}d</span>
                    </td>
                    <td>-</td>
                    <td>
                        <span style={{ color: '#32de84'}}>
                            Online
                        </span>
                    </td>
                </tr>
                ))
            )
        :
        // Render unhealthy analytics
        (server?.analyticHealthData?.analyticAlertData?.map((analyticInfo, index) => (
            analyticStatus === "Offline" &&
            <tr key={index}>
                <td>{siteName}</td>
                <td>{analyticInfo.application}</td>
                <td>
                    <svg style={{ color: '#EF5B5B' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-right-circle" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.854 5.146a.5.5 0 1 0-.708.708L9.243 9.95H6.475a.5.5 0 1 0 0 1h3.975a.5.5 0 0 0 .5-.5V6.475a.5.5 0 1 0-1 0v2.768L5.854 5.146z"/>
                    </svg>
                    <span style={{ color: '#EF5B5B', marginLeft: '.2rem' }}>{daysSince(analyticInfo.applicationOfflineSince)}d</span>
                </td>
                <td>{dateTimeUTC(analyticInfo.applicationOfflineSince)}</td>
                <td>
                    <span style={{ color: '#EF5B5B' }}>
                        {analyticStatus}
                    </span>
                </td>
            </tr>
            ))
        )
    )
}

export default AnalyticInfo;