import * as Actions from "../../constant/ActionTypes";
import * as api from "../../../api";

// Actions creators
export const listLicenses = () => async (dispatch) => {
    dispatch({ type: Actions.LIST_LICENSES_REQUEST });
    try {
        localStorage.setItem('list_licenses', '0');
        const response = await api.listLicenses();
        localStorage.setItem('list_licenses', '1');

       // Check if response has data
       if (!response || !response?.data) {
        console.log(`Response inside : ${response}`);
        dispatch({
            type: Actions.LIST_LICENSES_FAILURE,
            payload: `Error: ${'Internal error, please try again later.'}`
        });
        return;
        }

        // If the response is OK, dispatch success
        dispatch({
            type: Actions.LIST_LICENSES_SUCCESS,
            payload: response.data
        });

    } catch (error) {
        dispatch({
            type: Actions.DELETE_LICENSE_FAILURE,
            payload: error.message
        });
    }
}

// create new license 
export const createLicense = (license) => async (dispatch) =>  {
    try {
        const { data } = await api.createLicense(license);

        dispatch({
            type: Actions.CREATE_LICENSE,
            payload: data
        })
    } catch (error) {
        console.log(error);
    }
}

export const suspendLicenses = (suspendData) => async (dispatch) => {
    dispatch({ type: Actions.SUSPEND_LICENSE_REQUEST });
   try {  
    const response = await api.suspendLicenses(suspendData);
     // Check if response has data
     if (!response || !response?.data) {
        dispatch({
            type: Actions.SUSPEND_LICENSE_FAILURE,
            payload: `Error: ${'Internal error, please try again later.'}`
        });
        return;
    }

    dispatch({
        type: Actions.SUSPEND_LICENSE_SUCCESS,
        payload: response.data
    })
    } catch (error) {
        console.log(error);
        dispatch({
            type: Actions.SUSPEND_LICENSE_FAILURE,
            payload: error.message
        });
    }
}

export const reinstateLicenses = (reinstatedData) => async (dispatch) => {
    dispatch({ type: Actions.REINSTATE_LICENSE_REQUEST });
    try {  
     const response = await api.reinstateLicenses(reinstatedData);
      // Check if response has data
      if (!response || !response?.data) {
         dispatch({
             type: Actions.REINSTATE_LICENSE_FAILURE,
             payload: `Error: ${'Internal error, please try again later.'}`
         });
         return;
     }
 
     dispatch({
         type: Actions.REINSTATE_LICENSE_SUCCESS,
         payload: response.data
     })
     } catch (error) {
         console.log(error);
         dispatch({
             type: Actions.REINSTATE_LICENSE_FAILURE,
             payload: error.message
         });
     }
 }

 export const changeLicensePolicy = (policyData) => async (dispatch) => {
    try {  
        const { data } = await api.changeLicensePolicy(policyData);

     if (data === 200) {
        console.log(`HERE ....`)
        dispatch({
            type: Actions.CHANGE_LICENSE_POLICY,
            payload: policyData
        })
     }
     else {
        alert(`Failed to update policy due to internal error.`);
     }
        
     } catch (error) {
         console.log(error);
     }
 }

export const deleteLicenses = (deleteData) => async (dispatch) => {
    dispatch({ type: Actions.DELETE_LICENSE_REQUEST });
    try {
        const response = await api.deleteLicense(deleteData);

        if (response.status !== 200) {
            dispatch({
                type: Actions.DELETE_LICENSE_FAILURE,
                payload: `Error: ${response.statusText}`
            });
            return;
        }
        dispatch({
            type: Actions.DELETE_LICENSE_SUCCESS,
            payload: deleteData.key
        });
    } catch (error) {
        dispatch({
            type: Actions.DELETE_LICENSE_FAILURE,
            payload: error.message
        });
    }
}