import * as Actions from "../constant/ActionTypes";
import * as api from '../../api';

export const isUserRegistered = (user) => async (dispatch) => {
    try {
        const { data } = await api.isUserRegistered({ user: user });
       
        dispatch({
            type: Actions.IS_USER_REGISTERED,
            payload: data
        })
    } catch (error) {
        console.log(error);
    }
}