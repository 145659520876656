import React from 'react';
import './servergradedbar.css';


const GradedBar = ({barColor }) => {
  const color = barColor;

  return (
    <div className="graded-bar">
      <div
        className="graded-bar-inner"
        style={{ backgroundColor: color }}
      />
    </div>
  );
};

export default GradedBar;
