import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { RingLoader } from 'react-spinners';
import { ClipLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';

// error notification box
import { useToasts } from 'react-toast-notifications';


import './licenses.css';

import { listLicenses } from '../../../redux/actions/licenses/licenseActions';

import License from './License';
import LicenseCreateForm from './LicenseCreateForm';

const Licneses = () => {
  const [modalOpenCreate, setModalOpenCreate] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState('All');
  const [selectedUser, setSelectedUser] = useState('All');
  const [selectedPolicy, setSelectedPolicy] = useState('All');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const dispatch = useDispatch();

  const isSmallScreen = window.innerWidth < 700;

  const { addToast } = useToasts();  

  const { licenses, loading, error } = useSelector(state => state.licenses);
  
  const businessTokenMappingData = useSelector(state => state.businessTokenMapping);
  const fetchUserResponse = useSelector(state => state.fetchUserResponse);
  
  // get businessess from the businessTokenMappingData
  const allowedBusinesses = businessTokenMappingData?.map(item => item?.business);

  // get user role
  const userRole = fetchUserResponse?.role;

// fetch unique businesses from the lisense data and filter out allowed businesses with the user role
let uniqueBusinesses = ['All', ...new Set(licenses?.map(license => userRole === 'L1' ? license.business : allowedBusinesses.includes(license.business) ? license.business : null))];

// update uniqueBusinesses if not blank
uniqueBusinesses = uniqueBusinesses.filter(item => item !== null && item !== '');  

// Count the number of licenses for each business
const businessCounts = licenses.reduce((acc, license) => {
  acc[license.business] = (acc[license.business] || 0) + 1;
  return acc;
}, {});
// Remove "All" from uniqueBusinesses before sorting
const sortedBusinesses = uniqueBusinesses.slice(1).sort((a, b) => businessCounts[b] - businessCounts[a]);
// Add "All" back at the beginning
const sortedUniqueBusinesses = ['All', ...sortedBusinesses];


  // filter by business
  let filteredByBusiness = selectedBusiness === 'All' ? 
    licenses :
    licenses.filter(
      license => license.business === selectedBusiness
    );

  // update filteredByBusiness if the businesses are allowed
  filteredByBusiness = filteredByBusiness.filter(license => userRole === 'L1' ? license : allowedBusinesses.includes(license.business) ? license : null);

  // update license.business if it is not blank and remove them
  filteredByBusiness = filteredByBusiness.filter(item => item.business !== null && item.business !== '');  

  // Fetch unique users from the filtered business list
  const uniqueUsers = ['All', ...new Set(filteredByBusiness.map((license) => license.user))];
  
  // filter by user
   const filteredByUser = selectedUser === 'All' ? 
   filteredByBusiness : 
   filteredByBusiness.filter(
     license => license.user === selectedUser
   );

   // Update license.user if it is blank
  const updatedFilteredByUser = filteredByUser.map(license => {
    if (!license.user) {
      license.user = 'unknown'; // or any default value you prefer
    }
    return license;
  });

  // filter by policy
  let filteredByPolicy = selectedPolicy === 'All' ?
    updatedFilteredByUser :
    updatedFilteredByUser.filter(
      license => license.policy === selectedPolicy
    );

  // get unique policies
  const uniquePolicies = ['All', ...new Set(updatedFilteredByUser.map((license) => license.policy))];



     //Modal toggle settings for create tag
     const handleModalOpenCreate = () => { 
      setModalOpenCreate(true);
      window.localStorage.setItem('license_key', '');
    };
     const handleModalCloseCreate = () => {
       setModalOpenCreate(false);
       localStorage.removeItem('newLicenses');
    };
  
     const handleRefresh = () => {
      setIsRefreshing(true);
      dispatch(listLicenses());
    };

    useEffect(() => {
      if (!loading.ListLicenses) {
        setIsRefreshing(false);
      }
    }, [loading.ListLicenses]);


  // select a business
  const handleBusinessChange = (event) => {
    setSelectedBusiness(event.target.value);
    setSelectedUser('All');
    setSelectedPolicy('All');
  };

  // select a policy
  const handlePolicyChange = (event) => {
    setSelectedPolicy(event.target.value);
  };

  // select a user
  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
    setSelectedPolicy('All');
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const searchedLicenses = filteredByPolicy.filter(license =>
    license.license_key.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCearSearch = () => {
    setSearchTerm('');
  };

  useEffect(() => {
    setIsFirstLoad(true);
    dispatch(listLicenses());
  }, [dispatch]);

  // redirect to the home route if the user role is not L1 / L2
  // useEffect(() => {
  //   console.log(`userRole: ${userRole}`);
  //   if (userRole !== 'L1' && userRole !== 'L2') {
  //     window.location.href = '/';
  //   }
  // }, [userRole]);

   // show error via toast notification
   useEffect(() => {
    if (error) {
      addToast(error, { appearance: 'error', autoDismiss: true });
    }
  }, [error, addToast]);

  // set selected business to 'All' if the updatedFilteredByUser becomes empty
  useEffect(() => {
    if (updatedFilteredByUser.length === 0) {
      setSelectedBusiness('All');
    }
  }, [updatedFilteredByUser]);


  

  return (
  <>
  { ((!loading.listLicenses || isRefreshing) && (isFirstLoad && filteredByPolicy.length)) || localStorage.getItem('newLicenses') ? 
   
    <div >
     
      <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }}>
        <Button variant='secondary'  onClick={handleModalOpenCreate}>Create new licenses</Button>
      </div>

      <div className='license-container'>

        {/* select site dropdown menu */}
        <div className='license-select-container'>

          <div className='license-business-select-container'>
            <div className='license-select-label'>
              Filter by business
            </div>
            <select
              className="license-info-dropdown"
              value={selectedBusiness}
              onChange={handleBusinessChange}
            >
              {sortedUniqueBusinesses.map((business, index) => (
                <option key={index} value={business}>
                  {business}
                </option>
              ))}
            </select>
          </div>

          <div className='license-user-select-container'>
            <div className='license-select-label'>
              Filter by user
            </div>
            <select
              className="license-info-dropdown"
              value={selectedUser}
              onChange={handleUserChange}
            >
              {uniqueUsers.map((user, index) => (
                <option key={index} value={user}>
                  {user}
                </option>
              ))}
            </select>
          </div>

          <div className='license-user-select-container' style={{ marginLeft: '1em' }}>
            <div className='license-select-label'>
              Filter by policy
            </div>
            <select
              className="license-info-dropdown"
              value={selectedPolicy}
              onChange={handlePolicyChange}
            >
              {uniquePolicies.map((policy, index) => (
                <option key={index} value={policy}>
                  {policy}
                </option>
              ))}
            </select>
          </div>

        </div>

        <div style={{ marginTop: '2em' }}>
          <button onClick={handleRefresh} className='refresh-icon-container'>
            <span>&#x21bb;</span>
          </button>
          <div className='total-license'>Total licenses: {searchedLicenses.length}</div>
        </div>

        {/* search box to fetch licenses given licnese key */}
      
         <div className='search-input-container'>
          <input
            type="text"
            placeholder="Search by license key ..."
            value={searchTerm}
            onChange={handleSearchTermChange}
            className="search-input"
          />
         {
          searchTerm && 
          <button className='search-input-clear' onClick={handleCearSearch}>
            &#x2715;
          </button>
         }
        </div>


        {
          loading.listLicenses && !error &&
          <div className='overlay'>
            <ClipLoader size={ !isSmallScreen ? 130 : 70} color="#F49523"/>
          </div>
        }
        { searchedLicenses.length > 0 ?
        <div className='table-container-license'>
          <table >    
            <thead>
              <tr>
                <th>Business</th>
                <th>License Key</th>
                <th>Name</th>
                <th>Status</th>
                <th>user</th>
                <th>Created</th>
                <th>Updated</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {searchedLicenses.length && searchedLicenses?.map((license, index) => (              
                  <License key={index} license={license} />              
              ))}
            </tbody>
          </table> 
        </div> :
        <div className='no-license-found'>
          No license found!
        </div>
    }
          
      </div>

      {/* create new license modal */}
      <Modal size='lg' show={modalOpenCreate} onHide={handleModalCloseCreate} >
        <Modal.Header closeButton >
            <Modal.Title ><span className='create-license-modal-header-text'>Create new licenses</span></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#334066' }}>
            <LicenseCreateForm licenses={licenses} fetchUserResponse={fetchUserResponse} businessTokenData={businessTokenMappingData}/>
        </Modal.Body>                  
      </Modal>

    </div>

    :
    
    (!searchedLicenses.length || (loading.listLicenses && !isRefreshing)) &&
    <div className='loading-refresh-lifecycle'>
      <RingLoader size={ !isSmallScreen ? 300 : 200} color="#F49523"/>
    </div>
  }

  </>
      
  )
}

export default Licneses