import React, { useState } from 'react';

const CustomTooltipAck = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      style={{ position: 'relative', display: 'inline-block' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showTooltip && (
        <div
          style={{
            position: 'absolute',
            top: '-130%',
            right: '-180%',
            transform: 'translateX(-50%)',
            background: '#384670',
            color: '#fff',
            padding: '5px',
            borderRadius: '0.2rem',
            zIndex: '999',
            fontSize: '0.6rem',
            fontWeight: '500',
            width: 'fit-content',
                
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default CustomTooltipAck;
