import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { BeatLoader } from 'react-spinners';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import logo from '../../images/logo_report.png';
import { reportFooter } from '../../config';

const TableToPDF = ({ fromDate, toDate }) => {
  const [loading, setLoading] = useState(true);  const report = useSelector(state => state.report);

  function convertImageDimensionsToPercentage(doc, widthPercentage, heightPercentage) {
    // Get page dimensions
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    // Calculate the width and height in points
    const imageWidth = (pageWidth * widthPercentage) / 100;
    const imageHeight = (pageHeight * heightPercentage) / 100;

    // Return the calculated dimensions
    return { width: imageWidth, height: imageHeight };
}

  
  useEffect(() => {
    if (window.localStorage.getItem('fetch_report') === 'true') {
      setLoading(false);
    }
  }, [report, loading]);

  function drawLine(doc, x1, y1, x2, y2) {   
    const lineWidth = 0.4;   
    const lineColor = [106,90,205];
  
    // Set the line width
    doc.setLineWidth(lineWidth);
  
    // Set the line color
    doc.setDrawColor(lineColor[0], lineColor[1], lineColor[2]);
  
    // Draw the line from (x1, y1) to (x2, y2)
    doc.line(x1, y1, x2, y2);
  }

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
  }
  function formatCurrentDateToDDMMYYYYHHMMSS() {
    const currentDate = new Date(); // Get the current UTC date and time
  
    const day = String(currentDate.getUTCDate()).padStart(2, '0'); // Get day and pad with 0 if needed
    const month = String(currentDate.getUTCMonth() + 1).padStart(2, '0'); // Get month (add 1 as months are zero-based) and pad with 0 if needed
    const year = currentDate.getUTCFullYear(); // Get year
    const hours = String(currentDate.getUTCHours()).padStart(2, '0'); // Get hours and pad with 0 if needed
    const minutes = String(currentDate.getUTCMinutes()).padStart(2, '0'); // Get minutes and pad with 0 if needed
    const seconds = String(currentDate.getUTCSeconds()).padStart(2, '0'); // Get seconds and pad with 0 if needed
  
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  }
  
  const generatePDF = () => {
    setLoading(true);
    const doc = new jsPDF();
    const dimensions = convertImageDimensionsToPercentage(doc, 17, 7);
    doc.addImage(logo, 'PNG', 10, 10, dimensions.width, dimensions.height);

    // Add the main heading with report generation time
    const currentDate = formatCurrentDateToDDMMYYYYHHMMSS(); // Get the current UTC time
    
    // heading
    doc.setFontSize(16);
    doc.setTextColor(205, 92, 92);
    doc.setFont('helvetica', 'bold');
    doc.text('Health Alerts Report', 80, 25);    

    doc.setTextColor(0,0,0);
    doc.setFont('helvetica', 'normal');
    drawLine(doc, 10, 38, 200, 38);
    doc.setFontSize(9);
    doc.text(`The report is generated at ${currentDate}.`, 10, 45);   
    const fromDateUTC = new Date(fromDate);
    const fromDateFormatted = formatDate(fromDateUTC);
    const toDateUTC = new Date(toDate);
    const toDateFormatted = formatDate(toDateUTC);
    doc.text(`Selected date range: From ${fromDateFormatted} To ${toDateFormatted}.`, 10, 52);
    //doc.setFontSize(9);
    //doc.text(`*All times are shown in Coordinated Universal Time format.`, 10, 59);
    //drawLine(doc, 10, 74, 200, 74);
  

     // generate a table
     const generateTable = (title, data) => {

      let myColumnStyles = {
        0: { cellWidth:  25 },
        1: { cellWidth:  27},
        2: { cellWidth:  35},
        3: { cellWidth:  40},
        4: { cellWidth:  40},
        5: { cellWidth:  30}
      }

      if (title === 'Offline Cameras:' || title === 'Offline Storages:') {
          myColumnStyles = {
            0: { cellWidth:  30},
            1: { cellWidth:  27},
            2: { cellWidth:  25},
            3: { cellWidth:  35},
            4: { cellWidth:  30},
            5: { cellWidth:  30},
            6: { cellWidth:  20}
        }
      }

      if (title === 'Offline Other Devices:') {
        myColumnStyles = {
          0: { cellWidth:  30},
          1: { cellWidth:  20},
          2: { cellWidth:  25},
          3: { cellWidth:  20},
          4: { cellWidth:  25},
          5: { cellWidth:  25},
          6: { cellWidth:  25},
          7: { cellWidth:  20}
      }
    }    

      if (data?.length > 0) {
        doc.setFont('helvetica', 'bold');
        doc.text(title, 10, doc.autoTable.previous ? doc.autoTable.previous.finalY + 20 : 70);
        doc.setFont('helvetica', 'normal');
        const columns = Object.keys(data[0]);
        const rows = data.map(item => Object.values(item));

        const styles = {
          fontSize: 8, // Adjust the font size as per your requirement
          cellPadding: 2, // Adjust the cell padding as per your requirement
        };

        doc.autoTable({
          head: [columns],
          body: rows,          
          startY: doc.autoTable.previous ? doc.autoTable.previous.finalY + 22  : 72,
          columnStyles: myColumnStyles,
          theme: 'grid',
          margin: { left: 10 },
          styles: styles
        });
      }
    };

    // Generate tables for each array
    generateTable('Offline Servers:', report.offlineServers);
    generateTable('Offline Cameras:', report.offlineCameras);
    generateTable('Offline Storages:', report.offlineStorage);
    generateTable('Offline Other Devices:', report.additionalInfo);

    // footer
    
    if (doc.autoTable.previous) {
      doc.setFontSize(9);
      doc.text(reportFooter, 80,  doc.autoTable.previous.finalY + 20);
    } else {
      doc.setTextColor('red');
      doc.text('No data found in the selected date range.', 80, 70);
      doc.setFontSize(9);
      doc.setTextColor('black');
      doc.text(reportFooter, 80, 270);
    }

    doc.save('health_report.pdf');
    doc.autoTable.previous = null;
  };

  return (
     loading ? 
     <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'center' }}>
        <BeatLoader size={10}  color="black"/>
     </div>
     :
      <div>
        <Button variant='dark' onClick={generatePDF} className='download-pdf-btn'>Download PDF</Button>
        
      </div>
      
      
    
  );
};

export default TableToPDF;
