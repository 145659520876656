import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';

import { listDevices } from '../../redux/actions/deviceActions';
import Devices from './Devices';


const Modal = ({ serverOfflineSince, serverStatus, serverName, site }) => { 

  const [loading, setLoading] = useState(true);
  let currentDevices = useSelector(state => state.devices);
  
 // sort the devices by camera status such that offline cameras are displayed first
 currentDevices = currentDevices.sort((a, b) => {
  // First compare by cameraStatus
  if (a.cameraStatus > b.cameraStatus) {
    return 1;
  } else if (a.cameraStatus < b.cameraStatus) {
    return -1;
  } else {
    // If cameraStatus is the same, sort by cameraName
    return a.cameraName.localeCompare(b.cameraName);
  }
});

  const dispatch = useDispatch();
  const user = localStorage.getItem('email');



  useEffect(() => {
    // load offline devices for the selected server from sql table
    if (serverName) {
      dispatch(listDevices(serverName, user, site));
    }
     
  }, [serverName, dispatch, user])

  useEffect(() => {
    // check if the selected server name is present in the fetched device list arra
    const isServerNamePresent = currentDevices.some(device => device.serverName === serverName);
    if (currentDevices.length && isServerNamePresent) {
      setLoading(false);
    }
  }, [currentDevices, loading, serverName]);


  

  return (
    <div> 
      {loading ?  
      <div style={{ marginTop: '2em', textAlign: 'center' }}>
        <BeatLoader size={10} color="#64C6E6"/>
      </div> :
      (
        <Devices serverOfflineSince={serverOfflineSince} devices={currentDevices} serverStatus={serverStatus} serverName={serverName} site={site}/>
      )}   
            
    </div>
  );
};

export default Modal;
