import React from 'react';
import './servergradedbar.css';

const getColor = (value, min, max) => {
    const midpoint = (min + max) / 2;
    const adjustedMax = max - midpoint;
  
    let percentage;
    if (value <= midpoint) {
      percentage = (value - min) / (midpoint - min);
    } else {
      percentage = (value - midpoint) / adjustedMax;
    }
  
    const red = Math.min(255, Math.floor(255 * (1 - percentage)));
    const green = Math.min(255, Math.floor(255 * percentage));
  
    return `rgb(${red}, ${green}, 0)`;
};

const GradedBar = ({ value, min, max }) => {
  const color = getColor(value, min, max);

  return (
    <div className="graded-bar">
      <div
        className="graded-bar-inner"
        style={{ backgroundColor: color }}
      />
    </div>
  );
};

export default GradedBar;
