import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//  import BRAND_LOGO from '../../images/AOL_Gif.gif';
import BRAND_LOGO from '../../images/Monitoring_Ronin_Longer.gif';
 import '../../../src/styles/home.css';
 import Footer from '../Footer';
 import SideBar from '../SideBar';

 import Notif from '../notif/Notif'; //
 import {newFeature} from '../../redux/actions/newFeature'; //
 import SideBarSmallScreen from '../SideBarSmallScreen';



const Home = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    let user = window.localStorage.getItem('email');

    const serversResponseData = useSelector(state => state.serversData);
    const servers = serversResponseData.servers;
    const serversResponseCode = serversResponseData.code;

    const isSmallScreen = window.innerWidth < 600;

    // fetch loggned user email from the base app cookie
    function getCookie(name) {
      const cookies = document.cookie.split("; ");
      for (const cookie of cookies) {
        const [key, value] = cookie.split("=");
        if (key === name) return decodeURIComponent(value);
      }
      return null;
    }

    const emailFromCookies = getCookie("email");
    console.log('user email as fethced from base app cookies', emailFromCookies);
    // set to local storage
   if (emailFromCookies) {
    user = emailFromCookies;
    window.localStorage.setItem('email', user);
    }
    

    useEffect(() => {
        if (servers.length) {
          setLoading(false);
        }
      }, [servers, loading]);

    return (
        <>
        {!isSmallScreen &&  <div className='sidebar-container'>
            <SideBar />
        </div>}

        {/* <Notif /> */}

        <div className='home-container'>
            <div className='logo-container'>
                <img src={BRAND_LOGO} alt='SAMURAI' className='home-image'></img>
            </div>
            <div className='home-btn'>
                {user ? 
                (                    
                  <button className='home-btn-style'  onClick={() => navigate("/health")}><span style={{ fontSize: '1.1rem' }}>Go to the health dashboard</span></button>
                ) :
                <button  className='home-btn-style' onClick={() => navigate("/auth/signin")}><span style={{ fontSize: '100%' }}>Please Login to continue</span></button>
                }
               
            </div> 
       </div>

       {isSmallScreen ? (
        <div className="home-sidebar-small"><SideBarSmallScreen/></div>
          ) : null
        }

        <div className='home-footer-container'>
          <Footer /> 
        </div>
        
        </>
  );
};

export default Home;