import * as Actions from '../constant/ActionTypes';

const updateCameraLifecycleReducers = (response={}, action) => {
    switch(action.type) {
        case Actions.UPDATE_CAMERA_LIFE_CYCLE:
            return action.payload;

        default :
        return response;
    }
}
export default updateCameraLifecycleReducers;