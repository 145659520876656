import React, {useState, useEffect} from 'react';
import '../../styles/notif.css';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';



function Notif () {
    const [showBanner, setShowBanner] = useState(false);
    const [showInstruction, setShowInstruction] = useState(false);

    // const [bannerExpired, setBannerExpired] = useState(false);

    // const bannerDelayTime = 60000; // 1 minute = 60000 ms
    const newFeatures = useSelector(state => state.features); //
    
    // let showBanner = false;
    useEffect (() => {
        if (newFeatures.length) {
            console.log(`Line 14 features are ${newFeatures[0].release_date} `);

            //                             Restart
            const bannerExpired = window.localStorage.getItem('bannerExpired');

            console.log(`Line 48 bannerExpired ${bannerExpired}`);
            console.log(`Line 50 bannerExpired ${bannerExpired !== 'true'}`); // There is a problem with this one. Fix tomorrow


            const displayPeriodInMs = 30 * 24 * 60 * 60 * 1000; // 30 days in miliseconds
            
            const timeDiff = new Date() - new Date(newFeatures[0].release_date);

            if ((timeDiff <= displayPeriodInMs) && (bannerExpired !== 'true')) {
                setShowBanner(true);
            }
            else {
                setShowBanner(false);
            }

        }
    }, [newFeatures]);

    console.log(`Line 38 show Banner ${showBanner}`);

    // if the feature expired (30 days), don't show the banner. Set the feature_display status to false, once current_date - released date >= 30 days, set to true
    // 

    const handleCloseBanner = () => {
        window.localStorage.setItem(`bannerExpired`, 'true');
        // window.localStorage.removeItem(`bannerExpired`, true);
        setShowBanner(false); // set true because once banner is closed, won't show the notification again
    }

    const handleShowInstruction = () => {
        setShowInstruction(true);
    }

    const handleHideInstruction = () => {
        setShowInstruction(false);
    }

    const handleHideInstructionAndBanner = () => {
        window.localStorage.setItem(`bannerExpired`, 'true');
        setShowInstruction(false);
        setShowBanner(false);
    }

    return (
        <>
            {showBanner && (
                <div className="banner">
                    {/* <span className="banner-closebtn" onClick={handleCloseBanner}>&times;</span> */}
                    <p>{showBanner} New feature released! Check out our instruction video to learn how it works. 
                        Click <a className="banner-clickbtn" onClick={handleShowInstruction}>here</a> to watch. </p>
                    <button onClick={handleCloseBanner}>Acknowledged it!</button>
                </div>
            )};

            <Modal centered style={{ height: '50em'}} show={showInstruction} onHide={handleHideInstruction}>
                <Modal.Header  style={{background: '#071d2c', padding: 0 }} className="modal-header">
                    <Modal.Title style={{ width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#fafafa', fontSize: '0.8em', padding: '0 0.5em' }}>
                            <div>Instruction video</div>
                            <button title="close" className='modal-close' onClick={handleHideInstructionAndBanner}>
                                <span>X</span>
                            </button>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{background: '#071d2c', color: '#ccc' }}>
                    <iframe style={{width:'450px', height:'400px'}} src="https://www.youtube.com/embed/tgbNymZ7vqY"> </iframe>
                    {/* <button>Got it! Show me the feature</button> */}
                </Modal.Body>
            </Modal>
        </>
    );
}


export default Notif;