import React from 'react';
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import "../styles/sidebar.css";


import Card1 from '../images/Card1-ParkingCompliance.png';
import Card2 from '../images/Card2-ShogunDetectCountReID.png';
import Card3 from '../images/Card3-RoninHealthMonitoring.png';

import { webURL, card1Text, card2Text, card3Text } from '../config';

const SideBarSmallScreen = () => {
  return (
    <div className='sidebar-smallscreen-container'>

       
            <Card className='sidebar-smallscreen-card'>            
                <Card.Body style={{ padding: 0 }}>               
                <Card.Img variant="top" src={Card1} />
                <Card.Text className='card-text'>
                    {card1Text}
                </Card.Text>
                <a href={webURL} target='_blank' rel='noreferrer' style={{ backgroundColor: 'transparent', margin: '0', padding: '0' }}>
                    <Button variant="text" className='card-btn' >Learn More</Button>
                </a>              
                </Card.Body>
            </Card>
      

       
            <Card className='sidebar-smallscreen-card'>            
                <Card.Body style={{ padding: 0 }}>            
                <Card.Img variant="top" src={Card2} />
                <Card.Text className='card-text'>
                    {card2Text}
                </Card.Text>              
                <a href={webURL} target='_blank'rel='noreferrer' style={{ backgroundColor: 'transparent', margin: '0', padding: '0' }}>
                    <Button variant="text" className='card-btn'  >Learn More</Button>
                </a>
                </Card.Body>
            </Card>
     

       
            <Card className='sidebar-smallscreen-card'>            
                <Card.Body style={{ padding: 0 }}>            
                <Card.Img variant="top" src={Card3} />
                <Card.Text className='card-text'>
                    {card3Text}
                </Card.Text>              
                <a href={webURL} target='_blank'rel='noreferrer' style={{ backgroundColor: 'transparent', margin: '0', padding: '0' }}>
                    <Button variant="text" className='card-btn'  >Learn More</Button>
                </a>
                </Card.Body>
            </Card>
        

    </div>
  )
}

export default SideBarSmallScreen