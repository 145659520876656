import '../../src/styles/footer.css';
import { webURL } from '../config';

const Footer = () => {
    return (
    <footer className='footer-container'>     
      <div className="footer-text">© 2024 Copyright:
            <a href={webURL} target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">  <span style={{ color: '#F49523', fontWeight: 'bold' }}>{webURL}</span></a>
          </div>     
    </footer>
    )
}
export default Footer;