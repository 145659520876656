import * as Actions from '../../constant/ActionTypes';

const licensePagesReducers = (pages=1, action) => {
    switch(action.type) {
        case Actions.TOTAL_PAGES:    
            return action.payload;      
            
        default:
            return pages;
    }
}

export default licensePagesReducers;