import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import store from './redux/store';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';

const rootElement = document.getElementById('root');

// Check if the root element exists before attempting to render
if (rootElement) {
  // Instead of ReactDOM.render, use createRoot
  const root = createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <ToastProvider >
          <App />
        </ToastProvider>       
      </Provider>
    </React.StrictMode>
  );
} 