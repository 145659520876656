export const webURL = 'https://www.artoflogic.ai';

// export const card1Text = 'With over 80 objects in our library, we can detect their presence. When combined with our BI Dashboard you can track asset utilisation and much more.';
// export const card2Text = 'Unparalleled People Count, Dwell and Queue Detect people with or without facial recognition';
// export const card3Text = 'Vehicle & Parking: LPR Number Plate Recognition with Colour, Make and Model';

export const card1Text = 'ALPR-powered vehicle recognition and parking enforcement. Track license plates and ensure smooth access, security, and compliance.';
export const card2Text = 'Advanced people and object detection with Re-ID. Track movement, dwell time, queues, and crossing lines for precise insights. Enhance operations with real-time analytics and BI integration.';
export const card3Text = 'Real-time diagnostics, proactive alerts, and centralized insights to prevent downtime and optimize performance.';


export const policyNames = [
    '1-Channel Samurai Count',
    '2-Channel Samurai Count',
    '4-Channel Samurai Count',
    '1-Channel Samurai Detect',
    '2-Channel Samurai Detect',
    '4-Channel Samurai Detect',
    '6-Channel Health Monitoring',
    '50-Channel Health Monitoring',
    '100-Channel Health Monitoring',
    '4-Channel Parking Management',
    '2-Channel Parking Management',
    '4-Channel Corsight',
    '2-channel Object',
    '50-Channel Health Monitoring Demo',
    '4-Channel Samurai Count Demo',
    '4-Channel Samurai Detect Demo',
    '1-Channel BASE DEMO',
    '2-Channel BASE DEMO',
    '4-Channel BASE DEMO',
    '8-Channel BASE DEMO',
    '1-Channel Base Suite',
    '2-Channel Base Suite',
    '3-Channel Base Suite',
    '4-Channel Base Suite',
    '5-Channel Base Suite',
    '6-Channel Base Suite',
    '7-Channel Base Suite',
    '8-Channel Base Suite',
    '9-Channel Base Suite',
    '10-Channel Base Suite',
    '11-Channel Base Suite',
    '12-Channel Base Suite',
    '13-Channel Base Suite',
    '14-Channel Base Suite',
    '15-Channel Base Suite',
    '16-Channel Base Suite',
    '17-Channel Base Suite',
    '18-Channel Base Suite',
    '19-Channel Base Suite',
    '20-Channel Base Suite',
    '21-Channel Base Suite',
    '22-Channel Base Suite',
    '23-Channel Base Suite',
    '24-Channel Base Suite',
    '1-Channel LPR DEMO',
    '2-Channel LPR DEMO',
    '4-Channel LPR DEMO',
    '1-Channel LPR',
    '2-Channel LPR',
    '4-Channel LPR',
    '1-Channel Infringement DEMO',
    '2-Channel Infringement DEMO',
    '4-Channel Infringement DEMO',
    '1-Channel Infringement',
    '2-Channel Infringement',
    '4-Channel Infringement',
  ];

  export const reportFooter = '© 2023 Art of Logic Pty Ltd. All rights reserved.';