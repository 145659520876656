import * as Actions from '../constant/ActionTypes';

const fetchUserReducers = (user={}, action) => {
    switch(action.type) {
        case Actions.FETCH_USER:           
            return action.payload;

        default :
        return user;
    }
}
export default fetchUserReducers;