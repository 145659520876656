import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import { useToasts } from 'react-toast-notifications';

import GradedBar from './cameraGradedBar/CameraGradedBar';
import CameraLifeExpectancyBar from './cameraGradedBar/CameraLifeExpectancyBar';
import CameraLastMaintenanceBar from './cameraGradedBar/CameraLastMaintenance';

import { updateCameraLifecycle } from '../../redux/actions/updateCameraLifecycle';

const alertIcon = require('../../images/alertIcon.png');
const restartIcon = require('../../images/restart.png');

const SelectedCameraLifecycle = ({ camera, selectedPeriod, serverName, selectedCameraLifeExpectancyDays, selectedCriticalDaysCamera }) => {

    const [showWarningModal, setShowWarningaModal] = useState(false);
    const [action, setAction] = useState(false);

    const loadingCameraLifecycleUpdate = useSelector(state => state.loadingCameraLifecycleUpdate);
    const updateCameraLifecyclResponse = useSelector(state => state.updateCameraLifecycle);    

    const user = localStorage.getItem('email');

    const { addToast } = useToasts();

    const dispatch = useDispatch();

    let barColor = 'green';

    // set color for expited cameras
    if (calculateDaysPassed(camera.cameraFirstCheckin) > parseInt(selectedCameraLifeExpectancyDays)) {
        barColor = 'red';
    }

    // set color for critical cameras
    if (parseInt(selectedPeriod) - calculateDaysPassed(camera.cameraFirstCheckin) < parseInt(selectedCriticalDaysCamera)
      && parseInt(selectedPeriod) - calculateDaysPassed(camera.cameraFirstCheckin) > 0) {
        barColor = 'orange';
    }

   
     function calculateDaysPassed(startDateUTC) {
         // Parse the start date as a UTC date
         const start = new Date(startDateUTC);
         // Get the current date in UTC
         const now = new Date();
         // Calculate the difference in milliseconds
         const diffInMilliseconds = now - start;
         // Convert the difference to days
         const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
         return diffInDays;
     }

    let cameraOperatingDays = calculateDaysPassed(camera.cameraFirstCheckin);

    const cameraRemainingLifeExpectancyDays = selectedCameraLifeExpectancyDays - cameraOperatingDays;

    let  cameraLastMaintenance = calculateDaysPassed(camera.cameraLastMaintenance);
    if (!camera.cameraLastMaintenance) {
      cameraLastMaintenance = 'No maintenance performed';
    }

    // cameras lifecycle bar
    const minLifecycleDays = 0;
    const maxLifecycleDays = selectedPeriod;

    // camera life expectancy bar
    const minCameraLifeExpectancy = 0;
    const maxCameraLifeExpectancy = selectedCameraLifeExpectancyDays;
    const currentCameraLifeExpectancy = cameraRemainingLifeExpectancyDays;

    // camera last maintenance bar
    const minCameraLastMaintenance = 0;
    const maxCameraLastMaintenance = selectedPeriod;
    let currentCameraLastMaintenance = cameraLastMaintenance;
    if (cameraLastMaintenance === 'No maintenance performed') {
      currentCameraLastMaintenance = selectedPeriod;
    }
 
     const handleResetOperatingTime = () => {
        setAction(true);
        const payload = { hwid: camera.hwid, camera_name:  camera.cameraName, camera_id: camera.cameraId, server_name: serverName, user: user };
        dispatch(updateCameraLifecycle(payload));
        closeWarningModal();
    }

      // Warming Modal control
      const openWarningModal = () => {
        if (cameraLastMaintenance === 0) {
          const errorMsg = `Maintenance event of camera ${camera.cameraName} was already updated today.`;
          addToast(errorMsg, {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 9000  
          });
          return;
        }
        setShowWarningaModal(true);
    }
    const closeWarningModal = () => {
        setShowWarningaModal(false);
    };

    useEffect(() => {
        
        if (action && updateCameraLifecyclResponse.code === 200) {
          
          const successMsg = "Operating days has been reset to zero.";
          addToast(successMsg, {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 9000  
          });
          setAction(false);
          
        }

        if (action && updateCameraLifecyclResponse.code === 500) {
           
          const errorMsg = "Internal server error. Please try after some time.";
          addToast(errorMsg, {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 9000
          });
          setAction(false);
        }     
      }, [updateCameraLifecyclResponse.code, addToast, action]);

  return (
    <tr>
        
        <td>
            {camera.cameraName}
        </td>
       
        <td>
          <div style={{ display: 'flex' }}>
              <div style={{ width: '2em' }}>{cameraOperatingDays}</div>
              <GradedBar barColor={barColor} />
              {
                calculateDaysPassed(camera.cameraFirstCheckin) > selectedPeriod ?
                <div>
                  <img src={alertIcon} alt='Alert' style={{ height: '1.6em', marginLeft: '0.5em' }}/>
                </div> : null                
              }
          </div>
        </td>

        <td>
          <div style={{ display: 'flex' }}>
            <div style={{ width: cameraLastMaintenance === 'No maintenance performed' ? '13em' : '2em' }}>{ cameraLastMaintenance }</div>
            {/* <CameraLastMaintenanceBar value={currentCameraLastMaintenance} min={minCameraLastMaintenance} max={maxCameraLastMaintenance} />
            {
              !camera.cameraLastMaintenance ?
              <div>
                <img src={alertIcon} alt='Alert' style={{ height: '1.6em', marginLeft: '0.5em' }}/>
              </div> : null
              } */}
          </div>
        </td>

        <td>          
          <div style={{ display: 'flex' }}>
            <div style={{ width: '2em' }}>{ cameraRemainingLifeExpectancyDays }</div>
              <CameraLifeExpectancyBar value={currentCameraLifeExpectancy} min={minCameraLifeExpectancy} max={maxCameraLifeExpectancy} />
              {
                calculateDaysPassed(camera.cameraFirstCheckin) >= parseInt(selectedCameraLifeExpectancyDays) ?
                <div>
                  <img src={alertIcon} alt='Alert' style={{ height: '1.6em', marginLeft: '0.5em' }}/>
                </div> : null
              }
          </div>           
        </td>

        <td>
            { 
              !loadingCameraLifecycleUpdate ?
                  <button className='reset-btn' onClick={openWarningModal}>
                      <img src={restartIcon} alt='Reset' style={{ height: '1.5em' }}/>
                  </button> :
                  <span>
                      <BeatLoader size={10} color="gray"/>
                  </span>
            }
        </td>

         {/* Warning Modal*/}

        <Modal size='sm' show={showWarningModal} onHide={closeWarningModal}  >
            <Modal.Header closeButton style={{ background: '#202841' }} closeVariant='white'>
            <Modal.Title className='cameras-modal-title'>Are you sure you want to confirm maintenance event for camera <span style={{ color: 'orange' }}>{camera.cameraName}</span></Modal.Title>
            </Modal.Header>

            <Modal.Body style={{background: '#202841', paddingRight:0 }}>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Button variant="danger" size='sm' onClick={handleResetOperatingTime} style={{ width: '5em' }}>Yes</Button>
                <Button variant="secondary" size='sm' onClick={closeWarningModal} style={{ width: '5em' }}>Cancel</Button>
            </div>
            </Modal.Body>
        </Modal>
        
    </tr>
  )
}

export default SelectedCameraLifecycle