import * as Actions from '../../constant/ActionTypes';

const initialState = {
    licenses: [],
    loading: {
        listLicenses: false,
        createUser: false,
        suspendLicense: false,
        reinstateLicense: false,
        changeLicensePolicy: false,
        deleteLicense: false
    },
    error: null
};

const licenseReducers = (state = initialState, action) => {
    switch(action.type) {
        case Actions.LIST_LICENSES_REQUEST:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    listLicenses: true
                },
                error: null
            };

        case Actions.LIST_LICENSES_SUCCESS:
            return {
                ...state,
                licenses: action.payload,
                loading: {
                    ...state.loading,
                    listLicenses: false
                },
                error: null
            };

        case Actions.LIST_LICENSES_FAILURE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    listLicenses: false
                },
                error: action.payload
            };

        case Actions.CREATE_USER_REQUEST:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    createUser: true
                },
                error: null
            };

        case Actions.CREATE_USER_SUCCESS:
            return {
                ...state,
                licenses: [...state.licenses, action.payload],
                loading: {
                    ...state.loading,
                    createUser: false
                },
                error: null
            };

        case Actions.CREATE_USER_FAILURE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    createUser: false
                },
                error: action.payload
            };

        case Actions.SUSPEND_LICENSE_REQUEST:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    suspendLicense: true
                },
                error: null
            };

        case Actions.SUSPEND_LICENSE_SUCCESS:
            return {
                ...state,
                licenses: state.licenses.map(license =>
                    license.license_key === action.payload
                        ? { ...license, status: 'SUSPENDED' } : license),
                loading: {
                    ...state.loading,
                    suspendLicense: false
                },
                error: null
            };

        case Actions.SUSPEND_LICENSE_FAILURE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    suspendLicense: false
                },
                error: action.payload
            };

        case Actions.REINSTATE_LICENSE_REQUEST:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    reinstateLicense: true
                },
                error: null
            };

        case Actions.REINSTATE_LICENSE_SUCCESS:
            return {
                ...state,
                licenses: state.licenses.map(license =>
                    license.license_key === action.payload
                        ? { ...license, status: 'ACTIVE' } : license),
                loading: {
                    ...state.loading,
                    reinstateLicense: false
                },
                error: null
            };

        case Actions.REINSTATE_LICENSE_FAILURE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    reinstateLicense: false
                },
                error: action.payload
            };

        case Actions.CHANGE_LICENSE_POLICY_REQUEST:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    changeLicensePolicy: true
                },
                error: null
            };

        case Actions.CHANGE_LICENSE_POLICY_SUCCESS:
            return {
                ...state,
                licenses: state.licenses.map(license =>
                    license.license_key === action.payload.licenseKey
                        ? { ...license, policy: action.payload.selectedPolicy } : license),
                loading: {
                    ...state.loading,
                    changeLicensePolicy: false
                },
                error: null
            };

        case Actions.CHANGE_LICENSE_POLICY_FAILURE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    changeLicensePolicy: false
                },
                error: action.payload
            };

        case Actions.DELETE_LICENSE_REQUEST:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    deleteLicense: true
                },
                error: null
            };

        case Actions.DELETE_LICENSE_SUCCESS:
            return {
                ...state,
                licenses: state.licenses.filter(license => license.license_key !== action.payload),
                loading: {
                    ...state.loading,
                    deleteLicense: false
                },
                error: null
            };

        case Actions.DELETE_LICENSE_FAILURE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    deleteLicense: false
                },
                error: action.payload
            };

        default:
            return state;
    }
};

export default licenseReducers;
