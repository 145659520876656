import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { listReport } from '../../redux/actions/reportActions';

import { fetchSelectedBusiness } from '../../redux/actions/fetchSelectedBusinessActions';

import PdfReport from './PdfReport';
import Home from '../home/Home';
import Footer from '../Footer';
import SideBar from '../SideBar';
import SideBarSmallScreen from '../SideBarSmallScreen';

import '../../../src/styles/reports.css';

const Reports = () => {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [loadPDF, setLoadPDF] = useState(false);
    const [selectedBusiness, setSelectedBusiness] = useState(''); 
    const [loading, setLoading] = useState(true);
    const [selectedSite, setSelectedSite] = useState('All');  

    const user = localStorage.getItem('email');

    // const servers = useSelector(state => state.servers);
    // Total data
    const serversResponseData = useSelector(state => state.serversData);
    const servers = serversResponseData.servers;
    const serversResponseCode = serversResponseData.code;

    const isSmallScreen = window.innerWidth < 600;

     // filter data based on business - total
     const handleBusinessChange = (event) => {
      setSelectedBusiness(event.target.value);
      setSelectedSite('All');
    };

    //const uniqueBusinesses = ['All', ...new Set(servers.map((row) => row.business))].sort();
  
    // optimized code
  const fetchUserResponse = useSelector(state => state.fetchUserResponse);
  const businesses = fetchUserResponse?.businesses;

  const filteredSites = businesses?.filter(obj => obj[selectedBusiness] !== undefined);
  // Extracting values from filtered sites
  let selectedSites = [];  
  if (selectedBusiness) {
     selectedSites = filteredSites?.flatMap(obj => Object.values(obj))[0];    
  }
  // sorted sites
  const sortedSites = ['All', ...new Set(selectedSites?.map((site) => site))].sort();
  const businessNames = businesses?.map(business => Object.keys(business)[0]);
  
  // total data
  // const filteredDataBusiness =
  //   selectedBusiness === 'All'
  //     ? servers
  //     : servers.filter((row) => row.business === selectedBusiness);


    // filter data based on site
    const handleSiteChange = (event) => {
      setSelectedSite(event.target.value);
    };
    //const uniqueSites = ['All', ...new Set(filteredDataBusiness.map((row) => row.site))].sort();


    const dispatch = useDispatch();

    const handleDateChange = (date, field) => {
        if (field === 'fromDate') {
          setFromDate(date);
        } else {
          setToDate(date);
        }
      };    

    const handleSubmit = (e) => {
        e.preventDefault();
        const fromDateUTC = new Date(fromDate);
        const toDateUTC = new Date(toDate);
        const reportData = {fromDate: fromDateUTC, toDate: toDateUTC, user:user, selectedBusiness: selectedBusiness, selectedSite: selectedSite};

        if (fromDate && toDate) {
          dispatch(listReport(reportData));
          setLoadPDF(true);
        }
        else {
          alert('Please select dates.')
        }
    }

    const handleClear = () => {
      setFromDate(null);
      setToDate(null);
      setLoadPDF(false);
      window.localStorage.setItem('fetch_report', false);
    }

    useEffect(() => {
      localStorage.setItem('nav', 'report');
    }, []);

    useEffect(() => {
      if (servers.length) {
        setLoading(false);
      }
    }, [servers, loading]);

    // useEffect(() => {
    //   if (user) {
    //       dispatch(listServers(user));
    //   }
    // }, [dispatch, user]);

  // set first business as default selected
  useEffect(() => {
    if (businessNames && !selectedBusiness) {
      setSelectedBusiness(businessNames[0]);
      dispatch(fetchSelectedBusiness(businessNames[0]));
    }      
  }, [selectedBusiness, businessNames, dispatch]);
    
 
  return (
    <>
     {!isSmallScreen &&  <div style={{ marginTop: '-3.5em' }}>
      <SideBar />
    </div>}
    { user ?
    <>
     {
            // loading ? 
            // <div className='report-loading-container'>                      
            //   <div>
            //     <img src={ isSmallScreen ? HIVE_SAMLL_SCREEN : HIVE_LARGE_SCREEN} alt='SAMURIA' className='report-info-loading-img'></img>
            //   </div>
            //   <div style={{ marginTop: '2em', textAlign: 'center' }}>
            //     <SyncLoader size={10} color="#36d7b7"/>
            //   </div>
            // </div> :
          
          <>
    <div className='report-container'>
          <div className='report-header'>
            <div className='report-header-text'>
              Health Alert Summary
            </div>
          </div>

      
        {/* select dropdown menu */}
        <div className='selectors-container-report'>

          {/* select business dropdown menu */}
          <div className='report-info-select-label'>Select a business:</div>            
          <select
            className="report-info-dropdown"
            value={selectedBusiness}
            onChange={handleBusinessChange}
            >
            {businessNames?.map((business, index) => (
              <option key={index} value={business}>
                {business}
              </option>
            ))}
          </select>
          

          {/* select site dropdown menu */}
          <label className='report-info-select-label'>Select a site:</label>           
          <select
          className="report-info-dropdown"
          value={selectedSite}
          onChange={handleSiteChange}
          >
          { sortedSites.map((site, index) => (
            <option key={index} value={site}>
              {site}
            </option>
          ))
          }
          </select>
        </div>

        <Form className='report-form-container'>
          <Form.Group controlId="fromDate">
            <Form.Label className='form-label'>From Date:</Form.Label><br></br>
            <DatePicker
              selected={fromDate}
              onChange={(date) => handleDateChange(date, 'fromDate')}
              className="form-control"
              placeholderText='select a date'
            />
          </Form.Group>
          <br></br>
          <Form.Group controlId="toDate">
            <Form.Label style={{ color: '#fafafa', fontWeight: '500' }}>To Date:</Form.Label><br></br>
            <DatePicker
              selected={toDate}
              onChange={(date) => handleDateChange(date, 'toDate')}
              className="form-control"
              placeholderText='select a date'
            />
          </Form.Group>
          <br></br>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button variant="success" onClick={handleSubmit} className='form-btn-submit'>
              Submit
            </button>           
          </div>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <button onClick={handleClear} className='form-btn-cancel'>
                Clear
            </button>
          </div>
          {loadPDF && <PdfReport fromDate={fromDate} toDate={toDate} />}
        </Form>         
          
    </div>

    {isSmallScreen ? (
      <div className="reports-sidebar-small"><SideBarSmallScreen/></div>
          ) : null
    }

     <div className='footer-container-report'>
      <Footer />
     </div>
    </>
    }
    
   </>
    :
    <Home />
    }
    </>

  )
}

export default Reports;