import * as Actions from '../constant/ActionTypes';


const initialState = {
    portCycleOutcomes: [], // List of the port-cycle objects
};

const portCycleReducers = (state = initialState, action) => {
    switch(action.type) {
        case Actions.ADD_PORT_CYCLE_OUTCOME:
            return {
                ...state,
                portCycleOutcomes: [...state.portCycleOutcomes, action.payload], // Append the new port-cycle outcome data
            };
        // case Actions.REMOVE_PORT_CYCLE_OUTCOME:
        //     return {
        //         ...state,
        //         portCycleOutcomes: state.portCycleOutcomes.filter((portOutcome) => portOutcome.port_number !== action.payload) // Delete a port-cycle outcome given port_number
        //     };
        case Actions.REMOVE_ALL_PORT_CYCLE_OUTCOMES:
            return {
                ...state,
                portCycleOutcomes: [], //Clear the port-cycle outcomes list when the pop up is closed
            };
        default: 
            return state;
    }
};

export default portCycleReducers;