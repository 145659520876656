import * as Actions from '../constant/ActionTypes';

const addUserFeatureReducer = (userFeature={responseCode: 500}, action) => {
   switch(action.type) {
        case Actions.ADD_USER_FEATURE_SEEN:
            return action.payload;
    
        default:
        return userFeature;
   }
}

export default addUserFeatureReducer;
