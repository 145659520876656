import * as Actions from "../constant/ActionTypes";
import * as api from '../../api';

export const newFeature = (email) => async (dispatch) => {
    try {
        const { data } = await api.newFeature(email);

        dispatch({
            type: Actions.NEW_FEATURE,
            payload: data
        })
    } catch (error) {
        console.log(error);
    }
}