import * as Actions from '../constant/ActionTypes';

const fetchSelectedBusinessReducers = (selectedBusiness=null, action) => {
    switch(action.type) {
        case Actions.SELECTED_BUSINESS:
            // console.log("selectedBusiness in reducer", action.payload);   
            return action.payload;

        default :
        return selectedBusiness;
    }
}
export default fetchSelectedBusinessReducers;