import * as Actions from '../constant/ActionTypes';

const newFeatureReducers = (features = [], action) => {
    switch(action.type) {
        case Actions.NEW_FEATURE:
            return action.payload;

        default:
            return features;
    }
}
export default newFeatureReducers;