import * as Actions from '../constant/ActionTypes';

const deleteOffCamReducer = (res={}, action) => {
    switch(action.type) {
        case Actions.DELETE_OFFLINE_CAMERA:
            return action.payload;
        
        default:
            return res;
    }
}

// const deleteOffCamReducer = (camera = [], action) => {
//     switch(action.type) {
//         case Actions.DELETE_OFFLINE_CAMERA:
//             return action.payload;
        
//         default:
//             return camera;
//     }
// }

export default deleteOffCamReducer;