import React, { useState } from 'react';

const CustomTooltipPortLayout = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const isSmallScreen = window.innerWidth < 600;


  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      style={{ position: 'relative'}}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showTooltip && (
        !isSmallScreen ?
        <div  
            style={{
                position: 'absolute',
                top: '100%',
                left: '86%',
                // transform: 'translateX(-50%)',
                color: '#fff',
                padding: '5px',
                borderRadius: '0px',
                zIndex: '999',
                fontSize: '0.8rem',
            }}
            >
          {text}
        </div>
        :
        <div  
            style={{
                position: 'absolute',
                top: '100%',
                left: '39%',
                color: '#fff',
                padding: '5px',
                borderRadius: '0px',
                zIndex: '999',
                fontSize: '0.65rem',
            }}
            >
        {text}
        </div>
      )}
    </div>
  );
};

export default CustomTooltipPortLayout;
