import React, {useState} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import '../../../src/styles/healthInfo.css';
import CustomTooltip from './CustomTooltip';
import ALL_HEALTHY_ICON from '../../images/All_servers_cameras_healthy.png';
import NO_DATA_ICON from '../../images/No_data.png';
import ALERT_ICON from '../../images/alertIcon.png';
// import INPROGRESS_ICON from '../../images/InProgress.png';

ChartJS.register(ArcElement, Tooltip, Legend);


function HealthCharts({ 
    data,
    totalOnlineServers,
    totalOfflineServers,
    totalOnlineDevices,
    totalOfflineDevices,
    totalOnlineAnalytics, 
    totalOfflineAnalytics,
    totalOnlineSwitches,
    totalOfflineSwitches,
    totalOnlineUPS,
    totalOfflineUPS,
    totalServers,
    totalDevices,
    totalAnalytics,
    totalSwitches,
    totalUPS
  }) 
  {

    const [hoveredIndex, setHoveredIndex] = useState(null);


  const isSmallScreen = window.innerWidth < 600;

  const options = {
    plugins: {
      legend: {
        labels: {
          color: '#ccc',
          font: {
            size: 12,
          } 
        },        
      },
    },
    rotation: -90,
    circumference: 180,
    cutout: 60,
    maintainAspectRatio: true,
    responsive: true, 
  };

  // options for smaller screen
  const options_small = {
    plugins: {
      legend: {
        labels: {
          color: '#ccc',
          font: {
            size: 11,
          }
        },        
      },    
     
    },
    rotation: -90,
    circumference: 180,
    cutout: 105, // Use a numerical value without the percentage symbol
    maintainAspectRatio: true,
    responsive: true, 
  };

  // options for no data available
  // const options_no_data = {
  //   plugins: {
  //     legend: {
  //       labels: {
  //         padding: 30,
  //         color: '#ccc',
  //         font: {
  //           size: 12,
  //         },
  //       },        
  //     },    
     
  //   },
  //   rotation: -90,
  //   circumference: 180,
  //   cutout: 61, // Use a numerical value without the percentage symbol
  //   maintainAspectRatio: true,
  //   responsive: true, 
  // };

  // Options for "No Data Available" for bigger screen
  const options_no_data = {
    plugins: {
      legend: {
        labels: {
          padding: 30,
          color: '#ccc',
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            // Customize tooltip for "No Data Available"
            if (data.ups.labels[0] === 'No Data Available') {
              return '';
            } else if (data.switches.labels[0] === 'No Data Available') {
              return '';
            } else if (data.analytics.labels[0] === 'No Data Available') {
              return '';
            } else if (data.devices.labels[0] === 'No Data Available') {
              return '';
            } else if (data.servers.labels[0] === 'No Data Available') {
              return '';
            }
            else {
              return `${tooltipItem.label}: ${tooltipItem.raw}`;
            }
           
          },
        },
      },
    },
    rotation: -90,
    circumference: 180,
    cutout: 61,
    maintainAspectRatio: true,
    responsive: true,
    onHover: (event, chartElement) => {
      if (chartElement.length) {
        const index = chartElement[0].index;
        setHoveredIndex(index);
      } else {
        setHoveredIndex(null);
      }
    },
  };

  // Options for "No Data Available" smaller screen
  const options_small_no_data = {
    plugins: {
      legend: {
        labels: {
          color: '#ccc',
          font: {
            size: 11,
          }
        },        
      },  
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            // Customize tooltip for "No Data Available"
            if (data.ups.labels[0] === 'No Data Available') {
              return '';
            } else if (data.switches.labels[0] === 'No Data Available') {
              return '';
            } else if (data.analytics.labels[0] === 'No Data Available') {
              return '';
            } else if (data.devices.labels[0] === 'No Data Available') {
              return '';
            } else if (data.servers.labels[0] === 'No Data Available') {
              return '';
            }
            else {
              return `${tooltipItem.label}: ${tooltipItem.raw}`;
            }
           
          },
        },
      },
    },
    rotation: -90,
    circumference: 180,
    cutout: 105, // Use a numerical value without the percentage symbol
    maintainAspectRatio: true,
    responsive: true,
    onHover: (event, chartElement) => {
      if (chartElement.length) {
        const index = chartElement[0].index;
        setHoveredIndex(index);
      } else {
        setHoveredIndex(null);
      }
    }, 
  };

  // set color for offline servers count at doughnut
  let totalOfflineServersColor = '#e9426C';
  if (!totalOfflineServers) {
    totalOfflineServersColor = '#32DE84';
  }

  // set color for offline cameras count at doughnut
  let totalOfflineDevicesColor = '#e9426C';
  if (!totalOfflineDevices) {
    totalOfflineDevicesColor = '#32DE84';
  }

  // set color for offline cameras count at doughnut
  let totalOfflineAnalyticsColor = '#e9426C';
  if (!totalOfflineAnalytics) {
    totalOfflineAnalyticsColor = '#32DE84';
  }

  // set color for offline cameras count at doughnut
  let totalOfflineSwitchesColor = '#e9426C';
  if (!totalOfflineSwitches) {
    totalOfflineSwitchesColor = '#32DE84';
  }


  // set color for offline cameras count at doughnut
  let totalOfflineUPSColor = '#e9426C';
  if (!totalOfflineUPS) {
    totalOfflineUPSColor = '#32DE84';
  }

  return (
    // For smaller screen
    isSmallScreen ?
    (<div className='chart-container' >
      <div >
        <span className='activity-label'>
          Server Activity     
        </span>
        <div className='doughnut-small'>
          <Doughnut data={data.servers} options={totalServers ? options_small : options_small_no_data}/>
          {
          // No data icon / No offline data icon / Some offline data icon
          !totalOfflineServers ? (
              !totalServers ?
              <div className='icon-tooltip-container'>
                <img className='doughnut-alert-icon' src={NO_DATA_ICON} alt="No data icon"/>
                <span className='tooltip-text-no-data'>No data</span>
              </div>
              :
              <div className='icon-tooltip-container'>
                <img className='doughnut-alert-icon' src={ALL_HEALTHY_ICON} alt="All healthy icon"/>
                <span className='tooltip-text-all-healthy'>All healthy</span>
              </div>)
            :
            (
              (totalServers === totalOfflineServers) ?
                <div className='icon-tooltip-container'>
                  <img className='doughnut-alert-icon' src={ALERT_ICON} alt="Unhealthy data icon"/>
                  <span className='tooltip-text-all-unhealthy'>All unhealthy</span>
                </div>
                :
                <div className='icon-tooltip-container'>
                  <img className='doughnut-alert-icon' src={ALERT_ICON} alt="Unhealthy data icon"/>
                  <span className='tooltip-text-some-unhealthy'>Some unhealthy</span>
                </div>
            )
            }
          <div className='chart-values' >           
              <span className='chart-offline-number-small' >
                <CustomTooltip text="Total Servers">
                  <span style={{ color: 'white' }}>{totalServers}</span>
                </CustomTooltip> /{' '}
                <CustomTooltip text="Offline Servers">
                  <span style={{ color: totalOfflineServersColor }}>{totalOfflineServers}</span>
                </CustomTooltip>
              </span>
             
          </div>
        </div>
      </div>
    
      <div >  
      <span className='activity-label'>
        Camera Activity
       </span>
      <div className='doughnut-small'>   
        <Doughnut  data={data.devices} options={totalDevices ? options_small : options_small_no_data} />
        {
          // No data icon / No offline data icon / Some offline data icon
          !totalOfflineDevices ? (
            !totalDevices ?
              <div className='icon-tooltip-container'>
                <img className='doughnut-alert-icon' src={NO_DATA_ICON} alt="No data icon"/>
                <span className='tooltip-text-no-data'>No data</span>
              </div>
              :
              <div className='icon-tooltip-container'>
                <img className='doughnut-alert-icon' src={ALL_HEALTHY_ICON} alt="All healthy icon"/>
                <span className='tooltip-text-all-healthy'>All healthy </span>
              </div>)
          :
          (
            (totalDevices === totalOfflineDevices) ?
              <div className='icon-tooltip-container'>
                <img className='doughnut-alert-icon' src={ALERT_ICON} alt="Unhealthy data icon"/>
                <span className='tooltip-text-all-unhealthy'>All unhealthy</span>
              </div>
              :
              <div className='icon-tooltip-container'>
                <img className='doughnut-alert-icon' src={ALERT_ICON} alt="Unhealthy data icon"/>
                <span className='tooltip-text-some-unhealthy'>Some unhealthy</span>
              </div>
            )
        }
        <div className='chart-values'>
          <span className='chart-offline-number-small' >
            <CustomTooltip text="Total Cameras">
              <span style={{ color: 'white' }}>{totalDevices}</span>
            </CustomTooltip> /{' '}
            <CustomTooltip text="Offline Cameras">
            <span style={{ color: totalOfflineDevicesColor }}>{totalOfflineDevices}</span>
            </CustomTooltip>
          </span>
        </div>
      </div>
      </div>

      <div>
        <span className='activity-label'>
          Analytics Activity     
        </span>
        <div className='doughnut-small'>
          <Doughnut data={data.analytics} options={totalAnalytics ? options_small : options_small_no_data} />
          {
          // No data icon / No offline data icon / Some offline data icon
          !totalOfflineAnalytics ? (
            !totalAnalytics ?
              <div className='icon-tooltip-container'>
                <img className='doughnut-alert-icon' src={NO_DATA_ICON} alt="No data icon"/>
                <span className='tooltip-text-no-data'>No data</span>
              </div>
              :
              <div className='icon-tooltip-container'>
                <img className='doughnut-alert-icon' src={ALL_HEALTHY_ICON} alt="All healthy icon"/>
                <span className='tooltip-text-all-healthy'>All healthy </span>
            </div>)
          :
          (
            (totalAnalytics === totalOfflineAnalytics) ?
              <div className='icon-tooltip-container'>
                <img className='doughnut-alert-icon' src={ALERT_ICON} alt="Unhealthy data icon"/>
                <span className='tooltip-text-all-unhealthy'>All unhealthy</span>
              </div>
              :
              <div className='icon-tooltip-container'>
                <img className='doughnut-alert-icon' src={ALERT_ICON} alt="Unhealthy data icon"/>
                <span className='tooltip-text-some-unhealthy'>Some unhealthy</span>
              </div>
            )
            }
          <div className='chart-values'>           
              <span className='chart-offline-number-small' >
                <CustomTooltip text="Total Analytics">
                  <span style={{ color: 'white' }}>{totalAnalytics}</span>
                </CustomTooltip> /{' '}
                <CustomTooltip text="Offline Analytics">
                  <span style={{ color: totalOfflineAnalyticsColor }}>{totalOfflineAnalytics}</span>
                </CustomTooltip>
              </span>
             
          </div>
        </div>
      </div>

      <div >
        <span className='activity-label'>
          UPS Activity     
        </span>
        <div className='doughnut-small'>
          <Doughnut data={data.ups} options={totalUPS ? options_small : options_small_no_data} />
          {
          // No data icon / No offline data icon / Some offline data icon
          !totalOfflineUPS ? (
            !totalUPS ?
              <div className='icon-tooltip-container'>
                <img className='doughnut-alert-icon' src={NO_DATA_ICON} alt="No data icon"/>
                <span className='tooltip-text-no-data'>No data</span>
              </div>
              :
              <div className='icon-tooltip-container'>
                <img className='doughnut-alert-icon' src={ALL_HEALTHY_ICON} alt="All healthy icon"/>
                <span className='tooltip-text-all-healthy'>All healthy</span>
              </div>)
          :
          (
            (totalUPS === totalOfflineUPS) ?
              <div className='icon-tooltip-container'>
                <img className='doughnut-alert-icon' src={ALERT_ICON} alt="Unhealthy data icon"/>
                <span className='tooltip-text-all-unhealthy'>All unhealthy</span>
              </div>
              :
              <div className='icon-tooltip-container'>
                <img className='doughnut-alert-icon' src={ALERT_ICON} alt="Unhealthy data icon"/>
                <span className='tooltip-text-some-unhealthy'>Some unhealthy</span>
              </div>
          )
          }
          <div className='chart-values'>           
              <span className='chart-offline-number-small' >
                <CustomTooltip text="Total UPS">
                  <span style={{ color: 'white' }}>{totalUPS}</span>
                </CustomTooltip> /{' '}
                <CustomTooltip text="Offline UPS">
                  <span style={{ color: totalOfflineUPSColor }}>{totalOfflineUPS}</span>
                </CustomTooltip>
              </span>
             
          </div>
        </div>
      </div>

      <div>
        <span className='activity-label'>
          Switches Activity     
        </span>
        <div className='doughnut-small'>
          <Doughnut data={data.switches} options={totalSwitches ? options_small : options_small_no_data} />
          {
            // No data icon / No offline data icon / Some offline data icon
          !totalOfflineSwitches ? (
            !totalSwitches ?
              <div className='icon-tooltip-container'>
                <img className='doughnut-alert-icon' src={NO_DATA_ICON} alt="No data icon"/>
                <span className='tooltip-text-no-data'>No data</span>
              </div>
              :
              <div className='icon-tooltip-container'>
                <img className='doughnut-alert-icon' src={ALL_HEALTHY_ICON} alt="All healthy icon"/>
                <span className='tooltip-text-all-healthy'>All healthy</span>
              </div>)
          :
          (
            (totalSwitches=== totalOfflineSwitches) ?
              <div className='icon-tooltip-container'>
                <img className='doughnut-alert-icon' src={ALERT_ICON} alt="Unhealthy data icon"/>
                <span className='tooltip-text-all-unhealthy'>All unhealthy</span>
              </div>
              :
              <img className='doughnut-alert-icon' src={ALERT_ICON} alt="Unhealthy data icon"/>
          )
          }
          <div className='chart-values'>           
              <span className='chart-offline-number-small' >
                <CustomTooltip text="Total Switches">
                  <span style={{ color: 'white' }}>{totalSwitches}</span>
                </CustomTooltip> /{' '}
                <CustomTooltip text="Offline Switches">
                  <span style={{ color: totalOfflineSwitchesColor }}>{totalOfflineSwitches}</span>
                </CustomTooltip>
              </span>
             
          </div>
        </div>
      </div>


    </div>
    )
    :
    // For bigger screen
    (<div className='chart-container' >
      <div >
        <span className='activity-label'>
          Server Activity     
        </span>
        <div style={{ position: 'relative', textAlign: 'center', width: "220px", height: "220px" }}>
          <Doughnut data={data.servers} options={totalServers ? options: options_no_data}/>
          {
            // No data icon / No offline data icon / Some offline data icon
            !totalOfflineServers ? (
              !totalServers ?
              <div className='icon-tooltip-container'>
                <img className='doughnut-alert-icon' src={NO_DATA_ICON} alt="No data icon"/>
                <span className='tooltip-text-no-data'>No data</span>
              </div>
              :
              <div className='icon-tooltip-container'>
                <img className='doughnut-alert-icon' src={ALL_HEALTHY_ICON} alt="All healthy icon"/>
                <span className='tooltip-text-all-healthy'>All healthy</span>
              </div>
            )
            : (
              (totalServers === totalOfflineServers) ?
                <div className='icon-tooltip-container'>
                  <img className='doughnut-alert-icon' src={ALERT_ICON} alt="Unhealthy data icon"/>
                  <span className='tooltip-text-all-unhealthy'>All unhealthy</span>
                </div>
                :
                <div className='icon-tooltip-container'>
                  <img className='doughnut-alert-icon' src={ALERT_ICON} alt="Unhealthy data icon"/>
                  <span className='tooltip-text-some-unhealthy'>Some unhealthy</span>
                </div>
              )
          }
          <div className='chart-values'>           
              <span className='chart-offline-number'>
                <CustomTooltip text="Total Servers">
                  <span style={{ color: 'white' }}>{totalServers}</span>
                </CustomTooltip> /{' '}
                <CustomTooltip text="Offline Servers">
                  <span style={{ color: totalOfflineServersColor }}>{totalOfflineServers}</span>
                </CustomTooltip>
              </span>
             
          </div>
        </div>
      </div>
    
      <div >  
      <span className='activity-label'>
        Camera Activity
       </span>
      <div style={{ position: 'relative', textAlign: 'center', width: '220px', height: '220px' }}>   
        <Doughnut  data={data.devices} options={totalDevices ? options : options_no_data}/>
          {
            // No data icon / No offline data icon / Some offline data icon
            !totalOfflineDevices ? (
              !totalDevices ?
                <div className='icon-tooltip-container'>
                  <img className='doughnut-alert-icon' src={NO_DATA_ICON} alt="No data icon"/>
                  <span className='tooltip-text-no-data'>No data</span>
                </div>
                :
                <div className='icon-tooltip-container'>
                  <img className='doughnut-alert-icon' src={ALL_HEALTHY_ICON} alt="All healthy icon"/>
                  <span className='tooltip-text-all-healthy'>All healthy </span>
                </div> 
            )
            : (
            (totalDevices === totalOfflineDevices) ?
              <div className='icon-tooltip-container'>
                <img className='doughnut-alert-icon' src={ALERT_ICON} alt="Unhealthy data icon"/>
                <span className='tooltip-text-all-unhealthy'>All unhealthy</span>
              </div>
              :
              <div className='icon-tooltip-container'>
                <img className='doughnut-alert-icon' src={ALERT_ICON} alt="Unhealthy data icon"/>
                <span className='tooltip-text-some-unhealthy'>Some unhealthy</span>
              </div>
            )
          }
        <div className='chart-values'>
          <span className='chart-offline-number' >
            <CustomTooltip text="Total Cameras">
              <span style={{ color: 'white' }}>{totalDevices}</span>
            </CustomTooltip> /{' '}
            <CustomTooltip text="Offline Cameras">
            <span style={{ color: totalOfflineDevicesColor }}>{totalOfflineDevices}</span>
            </CustomTooltip>
          </span>
        </div>
      </div>
      </div>

      <div >
        <span className='activity-label'>
          Analytics Activity     
        </span>
        <div style={{ position: 'relative', textAlign: 'center', width: '220px', height: '220px' }}>
          <Doughnut data={data.analytics} options={totalAnalytics ? options : options_no_data}/>
          {
            // No data icon / No offline data icon / Some offline data icon
            !totalOfflineAnalytics ? (
              !totalAnalytics ?
                <div className='icon-tooltip-container'>
                  <img className='doughnut-alert-icon' src={NO_DATA_ICON} alt="No data icon"/>
                  <span className='tooltip-text-no-data'>No data</span>
                </div>
                :
                <div className='icon-tooltip-container'>
                  <img className='doughnut-alert-icon' src={ALL_HEALTHY_ICON} alt="All healthy icon"/>
                  <span className='tooltip-text-all-healthy'>All healthy </span>
                </div>
            )
            : (
              (totalAnalytics === totalOfflineAnalytics) ?
                <div className='icon-tooltip-container'>
                  <img className='doughnut-alert-icon' src={ALERT_ICON} alt="Unhealthy data icon"/>
                  <span className='tooltip-text-all-unhealthy'>All unhealthy</span>
                </div>
                :
                <div className='icon-tooltip-container'>
                  <img className='doughnut-alert-icon' src={ALERT_ICON} alt="Unhealthy data icon"/>
                  <span className='tooltip-text-some-unhealthy'>Some unhealthy</span>
                </div>
              )
          }
          <div className='chart-values'>           
              <span className='chart-offline-number' >
                <CustomTooltip text="Total Analytics">
                  <span style={{ color: 'white' }}>{totalAnalytics}</span>
                </CustomTooltip> /{' '}
                <CustomTooltip text="Offline Analytics">
                  <span style={{ color: totalOfflineAnalyticsColor }}>{totalOfflineAnalytics}</span>
                </CustomTooltip>
              </span>
             
          </div>
        </div>
      </div>

      <div >
        <span className='activity-label'>
          UPS Activity     
        </span>
        <div style={{ position: 'relative', textAlign: 'center', width: '220px', height: '220px' }}>
          <Doughnut data={data.ups} options={totalUPS ? options : options_no_data}/>
          {
            // No data icon / No offline data icon / Some offline data icon
            !totalOfflineUPS ? (
              !totalUPS ?
                <div className='icon-tooltip-container'>
                  <img className='doughnut-alert-icon' src={NO_DATA_ICON} alt="No data icon"/>
                  <span className='tooltip-text-no-data'>No data</span>
                </div>
                :
                <div className='icon-tooltip-container'>
                  <img className='doughnut-alert-icon' src={ALL_HEALTHY_ICON} alt="All healthy icon"/>
                  <span className='tooltip-text-all-healthy'>All healthy</span>
                </div>
            )
            : (
              (totalUPS === totalOfflineUPS) ?
                <div className='icon-tooltip-container'>
                  <img className='doughnut-alert-icon' src={ALERT_ICON} alt="Unhealthy data icon"/>
                  <span className='tooltip-text-all-unhealthy'>All unhealthy</span>
                </div>
                :
                <div className='icon-tooltip-container'>
                  <img className='doughnut-alert-icon' src={ALERT_ICON} alt="Unhealthy data icon"/>
                  <span className='tooltip-text-some-unhealthy'>Some unhealthy</span>
                </div>
              )
          }
          <div className='chart-values'>           
              <span className='chart-offline-number' >
                <CustomTooltip text="Total UPS">
                  <span style={{ color: 'white' }}>{totalUPS}</span>
                </CustomTooltip> /{' '}
                <CustomTooltip text="Offline UPS">
                  <span style={{ color: totalOfflineUPSColor }}>{totalOfflineUPS}</span>
                </CustomTooltip>
              </span>
             
          </div>
        </div>
      </div>

      <div >
        <span className='activity-label'>
          Switches Activity     
        </span>
        <div style={{ position: 'relative', textAlign: 'center', width: '220px', height: '220px' }}>
          <Doughnut data={data.switches} options={totalSwitches ? options : options_no_data}/>
          {
            // No data icon / No offline data icon / Some offline data icon
            !totalOfflineSwitches ? (
              !totalSwitches ?
                <div className='icon-tooltip-container'>
                  <img className='doughnut-alert-icon' src={NO_DATA_ICON} alt="No data icon"/>
                  <span className='tooltip-text-no-data'>No data</span>
                </div>
                :
                <div className='icon-tooltip-container'>
                  <img className='doughnut-alert-icon' src={ALL_HEALTHY_ICON} alt="All healthy icon"/>
                  <span className='tooltip-text-all-healthy'>All healthy</span>
                </div>
            )
            : (
              (totalSwitches === totalOfflineSwitches) ?
                <div className='icon-tooltip-container'>
                  <img className='doughnut-alert-icon' src={ALERT_ICON} alt="Unhealthy data icon"/>
                  <span className='tooltip-text-all-unhealthy'>All unhealthy</span>
                </div>
                :
                <img className='doughnut-alert-icon' src={ALERT_ICON} alt="Unhealthy data icon"/>
              )
          }
          <div className='chart-values'>           
              <span className='chart-offline-number' >
                <CustomTooltip text="Total Switches">
                  <span style={{ color: 'white' }}>{totalSwitches}</span>
                </CustomTooltip> /{' '}
                <CustomTooltip text="Offline Switches">
                  <span style={{ color: totalOfflineSwitchesColor }}>{totalOfflineSwitches}</span>
                </CustomTooltip>
              </span>
             
          </div>
        </div>
      </div>

    </div>
    )
  );
}

export default HealthCharts;
