import * as Actions from "../constant/ActionTypes";
import * as api from '../../api';

export const listReport = (reportData) => async (dispatch) => {
    try {
        window.localStorage.setItem('fetch_report', false);
        const { data } = await api.listReport(reportData);
        window.localStorage.setItem('fetch_report', true);

        dispatch({
            type: Actions.REPORT,
            payload: data
        });
    } catch (error) {
        console.log(error);
    }
}