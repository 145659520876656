import React, { useState } from 'react';

// set up amplify config
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from '../../../src/aws-exports';

import '../../../src/styles/login.css';

Amplify.configure(awsconfig);

const ForgotPassword = ({  username, onClose }) => {

    const [newPassword, setNewPassword] = useState(''); 
    const [code, setCode] = useState('');
    const [step, setStep] = useState(1);   

    const handleForgotPassword = async () => {
        try {
          await Auth.forgotPassword(username);
          setStep(2);
        } catch (err) {
          console.log(err);
        }
      }
    
      const handleForgotPasswordSubmit = async () => {
        try {
          await Auth.forgotPasswordSubmit(username, code, newPassword);
          alert('your password has been changed successfully. Please login with the new password.');
          onClose();
          // You can redirect the user to the login page or display a success message.
        } catch (err) {
          console.log(err);
          alert(err.message);
        }
      }
   


  return (
  
        <div > 
            {step === 1 && (
                <div>
                    <div className='forgot-password-text'>Request Confirmation Code by clicking the button below. </div>   
                    
                    <button className='forgot-password-request-code-btn' onClick={handleForgotPassword}>Request code</button>
                </div>
            )}

            {step === 2 && (
                <div>
                    <div className='verification-code-label'>
                      A verification code has been sent to <span style={{ color: '#0066b2', fontWeight: '500' }}>{username}</span>.<br></br>
                      Please enter the code and new password below.
                    </div>
                    
                    <input
                        className='input-box-forgotpasssword'
                        placeholder="Verification Code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                    />
                    <br></br>
                    <br></br>
                    <input
                        className='input-box-forgotpasssword'
                        type="password"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <br></br>
                    <br></br>
                    <button className='forgot-password-submit-btn' onClick={handleForgotPasswordSubmit}>Submit</button>
                </div>
            )}
        </div>
  )
}

export default ForgotPassword