import * as Actions from "../constant/ActionTypes";
import * as api from '../../api';

export const deleteOfflineCamera = (camera_id, user) => async (dispatch) => {
    try {
        const { data } = await api.deleteOfflineCamera(camera_id, user);
        console.log(`delete offline camera action response ${data}`);

        dispatch({
            type: Actions.DELETE_OFFLINE_CAMERA,
            payload: data
        }) 
    } catch (error) {
        console.log(error);
    }
}