import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';



import '../../../src/styles/healthInfo.css';
import Device from './Device';
import { listDevices } from '../../redux/actions/deviceActions';


const Devices = ({ serverOfflineSince, devices, serverStatus, serverName, site }) => { 

    const [filteredDevices, setFilteredDevices] = useState(devices);
        
    const user = localStorage.getItem('email');
    
    const emailOptResponse = useSelector(state => state.optemailCamera);

    const dispatch = useDispatch();

    const deleteOfflineCameraResponse = useSelector(state => state.deleteOfflineCamera);
   

    const handleSearchedCamera = (e) => {
        // filter the devices based on the camera name
        const filteredDevices = devices.filter(device => device?.cameraName.toLowerCase().includes(e.target.value.toLowerCase()));
        // set the filtered devices to the devices state
        setFilteredDevices(filteredDevices);
    }

     useEffect(() => {
        // load offline devices for the selected server from sql table
        // console.log(`deleteOfflineCameraResponse ${JSON.stringify(deleteOfflineCameraResponse)}`);
        const deletedCameraId = deleteOfflineCameraResponse.cameraID;
        const deletedCameraResponseCode = deleteOfflineCameraResponse.responseCode;
        // console.log(`deleted camera id ${deletedCameraId}`);
        // console.log(`deleted camera response code ${deletedCameraResponseCode}`);
        
        if (serverName && deletedCameraResponseCode === 200 && deletedCameraId) {
        // remove the deleted camera from the devices list and update filteredDevices state
        const updatedDevices = devices.filter(device => device?.cameraId !== deletedCameraId);
        setFilteredDevices(updatedDevices);
        // fetch the updated devices list form the backend
          dispatch(listDevices(serverName, user, site));
        }
         
      }, [serverName, dispatch, user, deleteOfflineCameraResponse])
     
  return (
    <div className='table-container-device' style={{ fontSize: '.85em' }}>
        {/* add a search bar here to filter the devices given camera name */}
        <div className='search-bar'>
            <input type='text' placeholder='Search camera name' onChange={ handleSearchedCamera }/>
        </div>

        <table id='server-table'>
            <thead>
                <tr>
                    <th>Camera name</th>
                    <th >Server name</th>
                    <th >Status</th>
                    <th >Offline since</th>
                    <th >Mute notifications</th>
                </tr>
            </thead>
            <tbody>
                {filteredDevices.map((device, index) => (
                    <Device 
                    index={index}
                        device={device}
                        user={user} 
                        serverStatus={serverStatus} 
                        serverOfflineSince={serverOfflineSince} 
                        emailOptAllCameras={emailOptResponse}
                        serverName={serverName}
                        site={site}
                    />
                ))}
            </tbody>
        </table>
    </div>
  )
}

export default Devices