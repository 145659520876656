import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import "../../src/styles/sidebar.css";

import BRAND_LOGO from '../images/home-logo.png';

import Card1 from '../images/Card1-ParkingCompliance.png';
import Card2 from '../images/Card2-ShogunDetectCountReID.png';
import Card3 from '../images/Card3-RoninHealthMonitoring.png';


import { webURL, card1Text, card2Text, card3Text } from '../config';

import { fetchUser } from '../redux/actions/fetchUser';


const SideBar = () => {
    const [selectedLink, setSelectedLink] = useState(localStorage.getItem('selectedLink') || '');

    const dispatch = useDispatch();
    
    const userEmail = localStorage.getItem('email');

    const fetchUserResponse = useSelector(state => state.fetchUserResponse);

    const handleLinkClick = (link) => {
      setSelectedLink(link);
      localStorage.setItem('selectedLink', link);
    };

    // update user role periodically every minute
    useEffect(() => {
      const fetchData = () => {
        dispatch(fetchUser(userEmail));
      };
    
      // Fetch data initially
      fetchData();
    
      // Set interval to fetch data periodically (e.g., every minute)
      const intervalId = setInterval(fetchData, 1 * 60 * 1000);
    
      // Clear interval on component unmount
      return () => clearInterval(intervalId);
    }, [userEmail, dispatch]);

  useEffect(() => {
    const path = window.location.hash;
    if (path === '') {
      setSelectedLink('');
      localStorage.setItem('selectedLink', '');
    }
  }
  , []);
  

    

    return (
    
      <ul className='sidebar-ul'>         
          <div className='sidebar-ul-img-container'>
            <img  src={BRAND_LOGO} alt='HIVE' className='sidebar-ul-img'/>
          </div>
          <div className='sidelink-container'>
              <li className={selectedLink === 'health' ? 'selected' : ''}>
                  <a href='/#health' onClick={() => handleLinkClick('health')}>Health-info</a>           
              </li>
              <li className={selectedLink === 'report' ? 'selected' : ''}>
                  <a href='/#report' onClick={() => handleLinkClick('report')}>Generate-reports</a>
              </li>
              <li className={selectedLink === 'lifecycle' ? 'selected' : ''}>
                <a href='/#lifecycle' onClick={() => handleLinkClick('lifecycle')}>Asset-lifecycle</a>
              </li>

              { 
                (fetchUserResponse?.role === 'L1' || fetchUserResponse?.role === 'L2') &&                   
                  <li className={selectedLink === 'admin' ? 'selected' : ''}>
                    <a href='/#admin' onClick={() => handleLinkClick('admin')}>Access-control</a>
                  </li>  
              }
              {
                (fetchUserResponse?.role === 'L1') &&
                  <li className={selectedLink === 'super-admin' ? 'selected' : ''}>
                    <a href='/#super-admin' onClick={() => handleLinkClick('super-admin')}>Admin</a>
                  </li>
              }
          </div>
         

        <div className='sidebar-ul-card-container'>
          <li className='sidebar-ul-card-li'>
              <Card className='sidebar-ul-card'>
                <Card.Body className='sidebar-ul-card-body'>
                  <Card.Img variant="top" src={Card1} />
                  <Card.Text className='card-text'>
                   {card1Text}
                  </Card.Text>
                  <a href={webURL} target='_blank' rel='noreferrer' className='sidebar-ul-card-btn-container'>
                    <button variant="text" className='card-btn' >Learn More</button>
                  </a>              
                </Card.Body>
            </Card>
            </li>

            <li className='sidebar-ul-card-li'>
              <Card className='sidebar-ul-card'>            
                <Card.Body className='sidebar-ul-card-body'>
                    <Card.Img variant="top" src={Card2}/>
                  <Card.Text className='card-text'>
                  {card2Text}
                  </Card.Text>
                  <a href={webURL} target='_blank'rel='noreferrer' className='sidebar-ul-card-btn-container'>
                    <button className='card-btn'  >Learn More</button>
                  </a>
                </Card.Body>
              </Card>
            </li>

            <li className='sidebar-ul-card-li'>
              <Card className='sidebar-ul-card'>            
                <Card.Body className='sidebar-ul-card-body'>
                    <Card.Img variant="top" src={Card3}/>
                  <Card.Text className='card-text'>
                  {card3Text}
                  </Card.Text>          
                  <a href={webURL} target='_blank'rel='noreferrer' className='sidebar-ul-card-btn-container'>
                    <button variant="text" className='card-btn' >Learn More</ button>
                  </a>
                </Card.Body>
              </Card>
            </li>
        </div>        
      </ul>
  );
};

export default SideBar;
