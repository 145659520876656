import '../../../src/styles/healthInfo.css';
import React, {useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';


import PORT_UP_GREEN_ICON from '../../images/port-up-green.png';
import PORT_DOWN_GREEN_ICON from '../../images/port-down-green.png';

import PORT_UP_RED_ICON from '../../images/port-up-red.png';
import PORT_DOWN_RED_ICON from '../../images/port-down-red.png';

import PORT_UP_LOADING_ICON from '../../images/port-up-orange.png';
import PORT_DOWN_LOADING_ICON from '../../images/port-down-orange.png';

import { url } from '../../api/endpointURL';
import { ClipLoader } from 'react-spinners';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { addPortCycleOutcome } from '../../redux/actions/portCycle';
// import { ToastContainer, toast } from 'react-toastify';



const Port = ({filtered_port, server, user, position}) => {
    const INTERVAL = 30000 // 30 secs
    const TIMEOUT = 180000 // 3 minutes. Average 3 minutes to complete the port-cycle
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showOutcomePopup, setShowOutcomePopup] = useState(false);
    const [showPendingRequestPopup, setShowPendingRequestPopup] = useState(false);
    const [otherUser, setOtherUser] = useState(null);
    // const isSmallScreen = window.innerWidth < 600;

    const dispatch = useDispatch();

    const [loadingPortCycle, setLoadingPortCycle] = useState({
        switch_id: server.switchInfo.switch_id,
        port_id: filtered_port.port_id,
        loading: null, 
        completed: null, 
        outcome: null, 
        timeLeft: 0,
        timeTaken: 0
    });

    // For printing only. DELETE LATER
    // useEffect(() => {
    //     console.log(`Line 41 the loadingPortCycle is ${JSON.stringify(loadingPortCycle, null, 2)} `)

    // }, [loadingPortCycle.timeLeft])    
    
    // Handle open port-cycle popup and fetch port-cycle-outcome API
      const handleOpenPortCyclePopUp = async(portID, switchID, portNumber, portName) => {
        setShowConfirmation(false);

        const portData = {port_id: portID, event_creator: user};

        try {
            const response = await fetch(`${url}/port-cycle`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify(portData),  
            });
    
            // After dispatching a port cycle, if status is 200,
            if (response.status === 200) {
                // console.log(`Line 256                                       API SUCCESSFUL    `);
                //  Set its loading state to 'true' and initise timeLeft to 3 mins
                setLoadingPortCycle((prevState) => (
                        {...prevState, loading: true, completed: null, outcome: null, timeLeft: TIMEOUT/1000, timeTaken: 0}
                ));

                // setApiLoadingDuration(0);
        
                // Call the port-cycle-outcome API for 3 minutes every 30 seconds 
                start_port_cycle_polling_process(portID, switchID, portNumber, portName);
        }
            // If status is 202, the request is already pending by someone 
            else if (response.status === 202) {
                setShowPendingRequestPopup(true);
                const data = await response.json();
                // console.log(`Line 75 pending request from ${data.user}`); 
                setOtherUser(data.user);
            }
        }
        catch (error) {
          console.error('Error:', error);
        }
    }

    // Calling port-cycle-outcome API
    const call_port_cycle_outcome_api = async(portID, timeInterval, timeOut) => {
        const portData = {port_id: portID, event_creator: user};

        try {
            const response = await fetch(`${url}/port-cycle-outcome`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(portData), 
            });

            const data = await response.json();
            // console.log(`Line 323 the response data of port-cycle-outcome is ${JSON.stringify(data)}`);

            // when the port-cycle-outcome API returns 'complete' : 1 then stop the polling
            if (data.complete === 1) {
                clearInterval(timeInterval);
                clearTimeout(timeOut);
                // console.log("Polling stopped because the port-cycle completed.");
                // Set 'loading' to 'false', 'completed' to 'true' (data.complete), and 'outcome' to returned outcome 
                // setTimeout(() => {
                setShowOutcomePopup(true);
                setLoadingPortCycle((prevState) => (
                {...prevState, loading: false, completed: data.complete, outcome: data.outcome}
                ));
                // }, 5000)

                // if outcome = 1 => Success, outcome = 0, Failure
                // if (data.outcome === 1) {
                //     toast.success(
                //         <>
                //             <span className="toast-outcome"><b><p>Port {filtered_port.port_number} - Camera {filtered_port.port_name} </p></b> 
                //                 Success! Click to view the outcome table
                //             </span>
                //         </>
                //         , {
                //         position: 'top-right',
                //     });
                // }
                // else if (data.outcome === 0) {
                //     toast.error(
                //         <>
                //             <span className="toast-outcome"><b><p>Port {filtered_port.port_number} - Camera {filtered_port.port_name}</p></b> 
                //                 Attempted! Click to view the outcome table
                //             </span>
                //         </>
                //         , {
                //         position: 'top-right',
                //     });
                // }
            }
            return data;
        }
        catch(error){
            console.error('Error:', error);
            clearInterval(timeInterval);
            clearTimeout(timeOut);
        }
    }

    // Dispatch adding port-cycle outcome when port-cycle completes (complete = 1 and outcome = 0/1)
    useEffect (() => {
        if (loadingPortCycle.completed === 1 && loadingPortCycle.outcome !== null ) {
            // Add the port-cycle outcome to global portCycleOutcomes once port-cycle completes
            const portCycleOutcome = {
                switchID: server.switchInfo.switch_id,
                portNumber: filtered_port.port_number,
                portName: filtered_port.port_name,
                portOutcome: loadingPortCycle.outcome,
                timeTaken: formatTime(loadingPortCycle.timeTaken),
            }
            dispatch(addPortCycleOutcome(portCycleOutcome));
        }
    }, [loadingPortCycle.completed, loadingPortCycle.outcome])


    // Start the time out process for calling port-cycle-outcome API every 30 seconds for 2 minutes
    const start_port_cycle_polling_process = (portID, switchID, portNumber, portName) => {
        // Call the port-cycle-outcome API every 30 secs
        const timeInterval = setInterval(() => {
            call_port_cycle_outcome_api(portID, switchID, timeInterval, timeOut, portNumber, portName);
        }, INTERVAL);

        // Time out calling the port-cycle-outcome API after 3 mins
        const timeOut = setTimeout(() => {
            clearInterval(timeInterval);
            // set 'loading' to 'false' and 'timeLeft' to '0' once timed out (outcome remains null) 
            // setTimeout(() => {
            setShowOutcomePopup(true);
            setLoadingPortCycle((prevState) => (
                {...prevState, loading: false, timeLeft: 0, timeTaken: TIMEOUT/1000} 
            ));
            // }, 5000);
            // toast.error(
            //     <>
            //         <span className="toast-outcome"><b><p>Port {filtered_port.port_number} - Camera {filtered_port.port_name}</p></b> 
            //             Timed out!
            //         </span>
            //     </>
            //     , {
            //     position: 'top-right',
            // });

            // Add the port-cycle outcome to global portCycleOutcomes once port-cycle timed out
            const portCycleOutcome = {
                switchID: server.switchInfo.switch_id,
                portNumber: portNumber,
                portName: portName,
                portOutcome: null,
                timeTaken: formatTime(TIMEOUT/1000),
            }
            dispatch(addPortCycleOutcome(portCycleOutcome));

        }, TIMEOUT + 5000); // add extra 5 seconds so that the state has enough time to be updated when the 6th API runs

        return () => {
        clearInterval(timeInterval);
        clearTimeout(timeOut);
        }; 
    }

    // Handle port image click
    const handlePortImageClick = () => {
        setShowConfirmation(true);
        // toast.success(
        //     <div >
        //         <span className="toast-outcome"><b><p>Port {filtered_port.port_number} - Camera {filtered_port.port_name}</p></b> 
        //             Click to view the outcome table
        //         </span>
        //     </div>
        //     , {
        //     position: 'top-right',
        // });
    };

    // Countdown timer format. Format time as MM:SS (minutes:seconds)
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
    };


    // Count down by 1
    useEffect(() => {
        let interval;
        if (loadingPortCycle.loading === true && loadingPortCycle.timeLeft > 0){
            interval = setInterval(() => {
                setLoadingPortCycle((prevState) => (
                {...prevState, timeLeft: prevState.timeLeft - 1, timeTaken: (TIMEOUT/1000 + 1) - prevState.timeLeft}    
                ))
            }, 1000)
        }

        return () => clearInterval(interval);
    }, [loadingPortCycle.timeLeft] );


    // Handle hiding the popup
    const cancelDelete = () => {
        setShowConfirmation(false);
    };

    // Handle hiding the outcome popup
    const cancelDelete1 = () => {
        setShowOutcomePopup(false);
    };

    // Handle hiding the pending request popup
    const cancelDelete2 = () => {
        setShowPendingRequestPopup(false);
    };

    // Set port image
    const portImage = (port_status, port_loading, port_complete, port_outcome) => {
        // Top row
        if (position === "top") {
            // Online
            if (port_status === 1) {
                if (port_loading === true) {
                    return PORT_UP_LOADING_ICON;
                }
                if ((port_complete === 1) && (port_loading === false)) {
                    if(port_outcome === 1){
                        return PORT_UP_GREEN_ICON;
                    }
                    else {
                        return PORT_UP_RED_ICON;
                    }  
                }
                return PORT_UP_GREEN_ICON; 
            }
            // Offline
            else {
                if (port_loading === true) {
                    return PORT_UP_LOADING_ICON;
                }
                if ((port_complete === 1) && (port_loading === false)) {
                    if(port_outcome === 1){
                        return PORT_UP_GREEN_ICON;
                    }
                    else {
                        return PORT_UP_RED_ICON;
                    }  
                }
                return PORT_UP_RED_ICON;
            }
        }
        // Bottom row
        else if (position === "bottom") {
            // Online
            if (port_status === 1) {
                if (port_loading === true) {
                    return PORT_DOWN_LOADING_ICON;
                }
                if ((port_complete === 1) && (port_loading === false)) {
                    if(port_outcome === 1){
                        return PORT_DOWN_GREEN_ICON;
                    }
                    else {
                        return PORT_DOWN_RED_ICON;
                    }  
                }
                return PORT_DOWN_GREEN_ICON; 
            }
            // Offline
            else {
                if (port_loading === true) {
                    return PORT_DOWN_LOADING_ICON;
                }
                if ((port_complete === 1) && (port_loading === false)) {
                    if(port_outcome === 1){
                        return PORT_DOWN_GREEN_ICON;
                    }
                    else {
                        return PORT_DOWN_RED_ICON;
                    }  
                }
                return PORT_DOWN_RED_ICON;
            }
        }
    }
    

return (
    <>
    {/* If port is online, set image icon color to GREEN */}
    {filtered_port.port_status === 1 ?                                     
    // For clicked port, retrieve its loading status, if its true, change the port to orange
        loadingPortCycle.loading ?
            <div className="port-icon-name">
                {position === "bottom" ?
                <> 
                    <div className="port-name">{filtered_port.port_name}</div>
                    <p>{formatTime(loadingPortCycle.timeLeft)}</p>
                    <img className="port-icon-onclick-disabled" src={portImage(filtered_port.port_status, loadingPortCycle.loading, loadingPortCycle.completed, loadingPortCycle.outcome)} alt="port_icon" />
                    <ClipLoader 
                    className="custom-spinner"
                    color={"white"}
                    loading={true}
                    size={25}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    />
                </>
                :
                <>
                    <ClipLoader 
                    className="custom-spinner"
                    color={"white"}
                    loading={true}
                    size={25}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    />
                    <img className="port-icon-onclick-disabled" src={portImage(filtered_port.port_status, loadingPortCycle.loading, loadingPortCycle.completed, loadingPortCycle.outcome)} alt="port_icon" />
                    <p>{formatTime(loadingPortCycle.timeLeft)}</p>
                    <div className="port-name">{filtered_port.port_name}</div>
                </>
                }
            </div>
        :
            // if loading = 'false' and complete = 'true' => Render the new outcome (0: red / 1: green)
            loadingPortCycle.completed === 1 ?
                <div className="port-icon-name">
                    {position === "bottom" && <div className="port-name">{filtered_port.port_name}</div>}
                    <img onClick={() => handlePortImageClick()} className='port-icon' src={portImage(filtered_port.port_status, loadingPortCycle.loading, loadingPortCycle.completed, loadingPortCycle.outcome)} alt="port_icon" />
                    {position === "top" && <div className="port-name">{filtered_port.port_name}</div>}
                </div>
            :
                // if loading = 'false' and 'complete' != 'true' => Leave image as it is
                <div className="port-icon-name">
                    {position === "bottom" && <div className="port-name">{filtered_port.port_name}</div>}
                    <img onClick={() => handlePortImageClick()} className='port-icon' src={portImage(filtered_port.port_status, loadingPortCycle.loading, loadingPortCycle.completed, loadingPortCycle.outcome)} alt="port_icon" />
                    {position === "top" && <div className="port-name">{filtered_port.port_name}</div>}
                </div>
    :
    // If port is offline, set image icon color to RED 
        // For clicked port, retrieve its loading status, if its true, change the port to orange
        loadingPortCycle.loading ?
            <div className="port-icon-name">
                {position === "bottom" ?
                <>
                    <div className="port-name">{filtered_port.port_name}</div>
                    <p>{formatTime(loadingPortCycle.timeLeft)}</p>
                    <img className="port-icon-onclick-disabled" src={portImage(filtered_port.port_status, loadingPortCycle.loading, loadingPortCycle.completed, loadingPortCycle.outcome)} alt="port_icon" />
                    <ClipLoader 
                    className="custom-spinner"
                    color={"white"}
                    loading={true}
                    size={25}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    />
                </>
                :
                <>
                    <ClipLoader 
                    className="custom-spinner"
                    color={"white"}
                    loading={true}
                    size={25}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    />
                    <img className="port-icon-onclick-disabled" src={portImage(filtered_port.port_status, loadingPortCycle.loading, loadingPortCycle.completed, loadingPortCycle.outcome)} alt="port_icon" />
                    <p>{formatTime(loadingPortCycle.timeLeft)}</p>
                    <div className="port-name">{filtered_port.port_name}</div>
                </>
                }
            </div>
        :
            // if loading = 'false' and complete = 'true' => Render the new outcome (0: red / 1: green)
            loadingPortCycle.completed === 1 ?
                <div className="port-icon-name">
                    {position === "bottom" && <div className="port-name">{filtered_port.port_name}</div>}
                    <img onClick={() => handlePortImageClick()} className='port-icon' src={portImage(filtered_port.port_status, loadingPortCycle.loading, loadingPortCycle.completed, loadingPortCycle.outcome)} alt="port_icon" />
                    {position === "top" && <div className="port-name">{filtered_port.port_name}</div>}
                </div>
            :
                // if loading = 'false' and 'complete' != 'true' => Leave image as it is
                <div className="port-icon-name">
                    {position === "bottom" && <div className="port-name">{filtered_port.port_name}</div>}
                    <img onClick={() => handlePortImageClick()} className='port-icon' src={portImage(filtered_port.port_status, loadingPortCycle.loading, loadingPortCycle.completed, loadingPortCycle.outcome)} alt="port_icon" />
                    {position === "top" && <div className="port-name">{filtered_port.port_name}</div>}
                </div>
        }

    {/* Pop up to ask user if they want to perform a port-cycle */}
    <Modal show={showConfirmation} onHide={cancelDelete} className="custom-modal">
        <Modal.Header closeButton closeVariant={'white'} >
            <Modal.Title>
            <span style={{ color:'white', fontSize: 'medium' }}>
                Perform port-cycle
            </span>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className='delete-modal-body' >
            <div style={{ marginBottom: '1em' }}>
            Do you want to perform a port-cycle on port <b>{filtered_port.port_number}</b> connected to camera <b>{filtered_port.port_name}</b>? It may take up to 3 minutes.
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {
            <Button variant="danger" size='sm' onClick={() => handleOpenPortCyclePopUp(filtered_port.port_id, filtered_port.switch_id, filtered_port.port_number, filtered_port.port_name)}>Yes</Button>
            }
            <Button variant="secondary" size='sm' onClick={cancelDelete}>Cancel</Button>
            </div>
        </Modal.Body>
    </Modal>

    {/* Pop up to show the outcome of the port-cycle request */}
    <Modal show={showOutcomePopup} onHide={cancelDelete1} className="custom-modal">
        <Modal.Header closeButton closeVariant={'white'} className='delete-modal-header'>
            <Modal.Title>
            <span style={{ color:'white', fontSize: 'medium' }}>
                Port-cycle Outcome
            </span>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className='delete-modal-body'>
            {
            // if port-cycle-outcome API exceeds time limit
            loadingPortCycle.outcome === null ?
            <div style={{ marginBottom: '1em' }}>
                <b>Timed out</b>. API was not responding for port <b>{filtered_port.port_name}</b>. Please try again!
                <p>Outcome: null</p>
            </div>
            :
            <div style={{ marginBottom: '1em' }}>  
                <div className='table-container-outcome'>
                    <table id='outcome-table'>    
                    <thead>
                        <tr> 
                            <th>Switch Vendor</th>
                            <th>Switch Mac</th>                                                         
                            <th>Port Number</th>                                                         
                            <th>Camera Name</th>
                            <th>Cycle Outcome</th>
                            <th>Time Taken</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{server.switchInfo.switch_vendor}</td>
                            <td>{server.switchInfo.switch_id}</td>
                            <td>{filtered_port.port_number}</td>
                            <td>{filtered_port.port_name}</td>
                            <td>{loadingPortCycle.outcome}</td>
                            <td>{formatTime(loadingPortCycle.timeTaken)}</td>
                        </tr>
                        
                    </tbody>
                    </table> 
                </div>
            </div>
            }
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* <Button variant="primary" size='sm' onClick={cancelDelete1}>OK</Button> */}
            </div>
        </Modal.Body>           
    </Modal>

    {/* Pop up to let current user know there is already a pending port-cycle request performed by someone*/}
    <Modal show={showPendingRequestPopup} onHide={cancelDelete2} className="custom-modal">
        <Modal.Header closeButton closeVariant={'white'} className='delete-modal-header'>
            <Modal.Title>
            <span style={{ color:'white', fontSize: 'medium' }}>
                Warning!
            </span>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className='delete-modal-body'>
            { otherUser === user ?
                <div style={{ marginBottom: '1em' }}>
                Please wait up to 5 minutes for your current port-cycle request for port <b>{filtered_port.port_number}</b> connected to camera <b>{filtered_port.port_name}</b> to be completed!
                </div>
            :
                <div style={{ marginBottom: '1em' }}>
                Please wait up to 5 minutes! A port-cycle request for port <b>{filtered_port.port_number}</b> connected to camera <b>{filtered_port.port_name}</b> is being processed by <b>{otherUser}</b> 
                </div>
            }
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* <Button variant="primary" size='sm' onClick={cancelDelete2}>OK</Button> */}
            </div>
        </Modal.Body>           
    </Modal>

    {/* Testing only                   */}
    {/* <ToastContainer
        position="top-right"
        autoClose={false}
        stacked={true}
        // hideProgressBar={true}
        // closeButton={false}
        // draggable={false}
        // pauseOnHover={false}
      /> */}
    {/* <div>
        <button onClick={notify}>Notify!</button>
        <ToastContainer newestOnTop = {false} stacked autoClose = {false}/>
    </div> */}
    {/* here */}

    </>
    )
}


export default Port;