export const LIST_SERVERS = 'LIST_SERVERS';
export const LIST_DEVICES  ='LIST_DEVICES';
export const LOGOUT = 'LOGOUT';
export const ACK = 'ACK';
export const ACKED = 'ACKED';
export const STORAGE = 'STORAGE';
export const REPORT = 'REPORT';
export const IS_USER_REGISTERED = 'IS_USER_REGISTERED';
export const FETCH_USER = 'FETCH_USER';
export const ADD_USER = 'ADD_USER';
export const OPT_EMAIL = 'OPT_EMAIL';
export const OPT_EMAIL_CAMERA = 'OPT_EMAIL_CAMERA';
export const BUSINESS_TOKEN_MAPPING = 'BUSINESS_TOKEN_MAPPING';
export const SELECTED_BUSINESS = 'SELECTED_BUSINESS';

export const LIST_BUSINESSES = 'LIST_BUSINESSES';
export const LIST_SITES = 'LIST_SITES';
export const LIST_SERVERS_LIFECYCLE = 'LIST_SERVERS_LIFECYCLE';
export const LIST_CAMERAS_LIFECYCLE = 'LIST_SERVERS_LIFECYCLE';
export const LOADING = 'LOADING';
export const LOADING_UPDATE_SERVER_LIFE_CYCLE = 'LOADING_UPDATE_SERVER_LIFE_CYCLE';
export const LOADING_UPDATE_CAMERA_LIFE_CYCLE = 'LOADING_UPDATE_CAMERA_LIFE_CYCLE';
export const UPDATE_SERVER_LIFE_CYCLE = 'UPDATE_SERVER_LIFE_CYCLE';
export const UPDATE_CAMERA_LIFE_CYCLE = 'UPDATE_CAMERA_LIFE_CYCLE';
export const NEW_FEATURE = 'NEW_FEATURE';
export const DELETE_OFFLINE_CAMERA = 'DELETE_OFFLINE_CAMERA';
export const ADD_USER_FEATURE_SEEN = 'ADD_USER_FEATURE_SEEN';
export const ADD_PORT_CYCLE_OUTCOME = 'ADD_PORT_CYCLE_OUTCOME';
export const REMOVE_PORT_CYCLE_OUTCOME = 'REMOVE_PORT_CYCLE_OUTCOME';
export const REMOVE_ALL_PORT_CYCLE_OUTCOMES = 'REMOVE_ALL_PORT_CYCLE_OUTCOMES';


// License related
export const LIST_LICENSES = 'LIST_LICENSES'
export const CREATE_LICENSE = 'CREATE_LICENSE'
export const UPDATE_LICENSE = 'UPDATE_LICENSE'
export const DELETE_LICENSE = 'DELETE_LICENSE'
export const TOTAL_PAGES = 'TOTAL_PAGES'
export const COUNT = 'COUNT'
export const LIST_LICENSE_USERS = 'LIST_LICENSE_USERS'
export const SUSPEND_LICENSE = 'SUSPEND_LICENSE'
export const REINSTATE_LICENSE = 'REINSTATE_LICENSE'
export const CHANGE_LICENSE_POLICY = 'CHANGE_LICENSE_POLICY'

export const LIST_LICENSES_REQUEST = 'LIST_LICENSES_REQUEST';
export const LIST_LICENSES_SUCCESS = 'LIST_LICENSES_SUCCESS';
export const LIST_LICENSES_FAILURE = 'LIST_LICENSES_FAILURE';

export const DELETE_LICENSE_REQUEST = 'DELETE_LICENSE_REQUEST';
export const DELETE_LICENSE_SUCCESS = 'DELETE_LICENSE_SUCCESS';
export const DELETE_LICENSE_FAILURE = 'DELETE_LICENSE_FAILURE';

export const SUSPEND_LICENSE_REQUEST = 'SUSPEND_LICENSE_REQUEST';
export const SUSPEND_LICENSE_SUCCESS = 'SUSPEND_LICENSE_SUCCESS';
export const SUSPEND_LICENSE_FAILURE = 'SUSPEND_LICENSE_FAILURE';

export const REINSTATE_LICENSE_REQUEST = 'REINSTATE_LICENSE_REQUEST';
export const REINSTATE_LICENSE_SUCCESS = 'REINSTATE_LICENSE_SUCCESS';
export const REINSTATE_LICENSE_FAILURE = 'REINSTATE_LICENSE_FAILURE';

export const CHANGE_LICENSE_POLICY_REQUEST = 'CHANGE_LICENSE_POLICY_REQUEST';
export const CHANGE_LICENSE_POLICY_SUCCESS = 'CHANGE_LICENSE_POLICY_SUCCESS';
export const CHANGE_LICENSE_POLICY_FAILURE = 'CHANGE_LICENSE_POLICY_FAILURE';


// USER relted
export const LIST_USERS = 'LIST_USERS'
export const CREATE_USER = 'CREATE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const DELETE_USER = 'DELETE_USER'

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
