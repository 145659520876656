import * as Actions from "../constant/ActionTypes";
import * as api from '../../api';

export const postACK = (ackData) => async (dispatch) => {
    try {
        const { data } = await api.postACK(ackData);

        dispatch({
            type: Actions.ACK,
            payload: data
        })
    } catch (error) {
        console.log(error);
    }
}