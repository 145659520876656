import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// HashRouter to handle non defined server side routes
import { HashRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './components/home/Home';
import ProtectedRoute from './components/ProtectedRoute';

import SignIn from './components/auth/SignnIn';
import Navbar from './components/Header';
import Servers from './components/servers/Servers';
import Reports from './components/reports/Reports';
import AdminDashbaord from './components/admin/AdminDashbaord';
import SuperAdmin from './components/superAdmin/SuperAdmin';
import Lifecycle from './components/lifecycle/Lifecycle';

import ScheduledUpdate from './ScheduledUpdated';

library.add(fas);

function App() {

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);

  const fetchUserResponse = useSelector(state => state.fetchUserResponse);

  useEffect(() => {
    const handleResize = () => {
        setIsSmallScreen(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);
  
  return (
    <Router>
      <Navbar isSmallScreen={isSmallScreen}/>
      <ScheduledUpdate />
      <Routes>
        <Route exact path='/' element={<Home />}></Route>
        <Route exact path='/health' element={<Servers />}></Route>
        <Route exact path='/report' element={<Reports />}></Route>
        <Route exact path='/admin' element={<AdminDashbaord />}></Route>
        <Route exact path='/super-admin' element={
            <ProtectedRoute requiredLevel="L1" role={fetchUserResponse?.role}>
              <SuperAdmin />
            </ProtectedRoute>
          }></Route>
        <Route exact path='/lifecycle' element={<Lifecycle />}></Route>
        <Route exact path='/auth/signin' element={<SignIn />}></Route>    
      </Routes>
     
    </Router>
  );
}

export default App;
