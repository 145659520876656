import React, {useState, useEffect, useCallback} from 'react';
import Modal from 'react-bootstrap/Modal';
import {  useDispatch, useSelector } from 'react-redux';
import '../../styles/notifBoard.css';
import RoninIconSmall from '../../images/RoninIconSmall.png';
import { addUserFeatureSeen } from '../../redux/actions/addUserFeatureSeen';

import { newFeature } from '../../redux/actions/newFeature'; // Temp


function NotifBoard() {
    const [showInstruction, setShowInstruction] = useState(false);
    const [urlLink, setUrlLink] = useState(null);
    const [selectedFeatureID, setSelectedFeatureID] = useState(null); // for userFeatureSeen post API

    const newFeatures = useSelector(state => state.features);

    const isSmallScreen = window.innerWidth < 600;
    const dispatch = useDispatch();

    const userEmail = localStorage.getItem('email');

    const [viewStatusFeature, setViewStatusFeature] = useState(
        newFeatures.map((newFeature) => (
            {featureID: newFeature.feature_id, viewed: false}
        ))
    );

    const [featuresVisibility, setFeaturesVisibility] = useState(
        newFeatures.map(()=> true)
    );

    const handleShowInstruction = (url_link, index, feature_id) => {
        setUrlLink(url_link);
        setSelectedFeatureID(feature_id);
        setShowInstruction(true);

        setViewStatusFeature(prevFeatureStatus => {
            return prevFeatureStatus.map(feature => {
                if (feature.featureID === feature_id) {
                    return {...feature, viewed: true};
                }
                return feature;
            });
        });
    };

    // Display the notifications for 1 month from the release date. If no notification, disable/delete the button.
    useEffect(()=> {
        const currentDate = new Date();
        const releaseDates = newFeatures.map(feature => new Date (feature.release_date));

        setFeaturesVisibility((previousVisibility) => 
            previousVisibility.map((isVisible, index) => {
                const timeDiff = currentDate - releaseDates[index];
                const monthsDifference = timeDiff / (1000 * 3600 * 24 * 30);  // convert milliseconds in months
                
                if (monthsDifference >= 1) {
                    return false;
                }
                return isVisible;
            })
        );    
    }, [newFeatures])

    // Sort the notifications by date
    const sortedByDate_newFeatures = newFeatures.sort((a,b) => new Date(b.release_date) - new Date(a.release_date));

    // Hide instruction
    const handleHideInstruction = async() => {
        setShowInstruction(false);
        setSelectedFeatureID(null);
        const seen_status = 1; // for userFeature post API
        const seen_date = new Date();
        const userFeatureData = {email: userEmail, feature_id: selectedFeatureID, seen: seen_status, seen_date: seen_date};
        dispatch(addUserFeatureSeen(userFeatureData));

        await new Promise(resolve => setTimeout(resolve, 300)); // set time out for 50ms for the newFeature API to complete dispatch
        dispatch(newFeature(userEmail));
    }

    // Function to calculate how many weeks have passed from the release date until the current date
    function timeAgo(dateString) {
        const currentDate = new Date();
        // Convert the input date string into a Date object
        const pastDate = new Date(dateString);
        const timeDifference = currentDate - pastDate; // in miliseconds
        const days = timeDifference / (1000 * 60 * 60 * 24); // 1000ms * 60s * 60m * 24h
        
        // If less than 7 days, return the number of days
        if (days < 7) {
          return `${Math.floor(days)} day(s) ago`;
        }
      
        // If 1 week or more, calculate full weeks and display weeks only
        const weeks = Math.floor(days / 7); 
        return `${weeks} week(s) ago`;
      }
            
    
    return (
        <>
            {/* Notification Board */} 
            { !isSmallScreen ? 
            // for bigger screens
             (<div className="notification-container">
                <div id="notificationPanel" className="notification-panel">
                    <div className="notification-header">
                        <div className='notification-header-text'>Notifications</div>
                        <img src={RoninIconSmall} alt="Ronin Icon" className="ronin-icon-small" />
                    </div>
                    <ul className="notification-list">
                        {
                            sortedByDate_newFeatures.map((feature, index) => (
                                featuresVisibility[index] && ( 
                                <li className="notification-item" key={feature.feature_id}>
                                    {viewStatusFeature.find(item => item.featureID === feature.feature_id)?.viewed === false
                                    ?
                                    <p><strong>New feature alert - {feature.feature_title}! Click <button className="click-here" style={{color: "#F89532"}} onClick={() => handleShowInstruction(feature.url_link, index, feature.feature_id)}> here </button> to learn more. </strong></p>
                                    :
                                    <p>New feature alert - {feature.feature_title}! Click <button className="click-here" style={{color: "#0000FF"}} onClick={() => handleShowInstruction(feature.url_link, index, feature.feature_id)}> here </button> to learn more.</p>
                                    }
                                    <p className="feature-release-date">Release date: {timeAgo(feature.release_date)}</p>
                                </li>
                                )
                            ))
                        }
                    </ul>
                </div>
                {/* Instruction modal */}
                    <Modal centered style={{ height: '50em'}} show={showInstruction} className="instruction-modal">
                        <Modal.Header  style={{background: '#071d2c' }} className="modal-header">
                            <Modal.Title style={{ width: '100%' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#fafafa', fontSize: '0.8em', padding: '0 0.5em' }}>
                                    <div>Instruction video</div>
                                    <button title="close" className='modal-close' onClick={handleHideInstruction}>
                                        <span className='home-bottom-modal-title-skip-icon'> Skip &#10174;</span>
                                    </button>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{background: '#071d2c', color: '#ccc' }}>
                            <iframe style={{width:'450px', height:'400px'}} src={urlLink}> </iframe>
                        </Modal.Body>
                    </Modal>
            </div>
            )
            :
            // for small screens
            (
            <div className="notification-container">
                <div id="notificationPanel" className="notification-panel-small" style={{zIndex:'1', position: 'absolute'}}>
                    <div className="notification-header">
                        <div className='notification-header-text'>Notifications</div>
                        <img src={RoninIconSmall} alt="Ronin Icon" className="ronin-icon-small" />
                    </div>
                    <ul className="notification-list">
                        {
                            sortedByDate_newFeatures.map((feature, index) => (
                                featuresVisibility[index] && ( 
                                <li className="notification-item" key={feature.feature_id}>
                                    {viewStatusFeature.find(item => item.featureID === feature.feature_id)?.viewed === false
                                    ?
                                    <p><strong>New feature alert - {feature.feature_title}! Click <button className="click-here" style={{color: "#F89532"}} onClick={() => handleShowInstruction(feature.url_link, index, feature.feature_id)}> here </button> to learn more. </strong></p>
                                    :
                                    <p>New feature alert - {feature.feature_title}! Click <button className="click-here" style={{color: "#0000FF"}} onClick={() => handleShowInstruction(feature.url_link, index, feature.feature_id)}> here </button> to learn more.</p>
                                    }

                                    <p className="feature-release-date">Release date: {timeAgo(feature.release_date)}</p>
                                </li>
                                )
                            ))
                        }
                    </ul>
                </div>
                <div style={{zIndex:'2', position: 'absolute'}}>
                {/* Instruction modal */}
                <Modal centered style={{ height: '20em'}} show={showInstruction} className="instruction-modal">
                    <Modal.Header  style={{background: '#071d2c' }} className="modal-header">
                        <Modal.Title style={{ width: '100%' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#fafafa', fontSize: '0.8em', padding: '0 0.5em' }}>
                                <div>Instruction video</div>
                                <button title="close" className='modal-close' onClick={handleHideInstruction}>
                                    <span className='home-bottom-modal-title-skip-icon'> Skip &#10174;</span>
                                </button>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{background: '#071d2c', color: '#ccc' }}>
                        <iframe src={urlLink}> </iframe>
                    </Modal.Body>
                </Modal>
                </div>
            </div>
        )
        }
        </>
    );
}

export default NotifBoard;